import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setGoAutoProcesses, setOpenReopenCaseDlg } from "../udTreatSlice"
import { setBottomStatusMsg, setShowBottomStatusBar } from "../udTreatSlice"
import { caseManagement } from "@/gluelayer"
import {
  resetCase,
} from "@/core/app/slices/clinical/clinicalSlice"
import { fetchZipsAndLoadCase } from "@/core/app/slices/clinical/opencaseflow"
import { clearCache } from "@/core/app/slices/cache/cacheSlice"

export const ReopenCaseDlg = () => {
  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const { openReopenCaseDlg } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const onReopenOK = async () => {
    await dispatch(setOpenReopenCaseDlg(false))
    dispatch(setBottomStatusMsg("Reopen case."))
    // when presetup has finished then reopen case
    // clear the case cache
    await dispatch(clearCache({ caseId }))
    await dispatch(resetCase())

    await dispatch(setShowBottomStatusBar(false))
    await dispatch(fetchZipsAndLoadCase({caseId,patientId}));
    dispatch(setGoAutoProcesses(true))
  }
  const onCancel = () => {
    dispatch(setOpenReopenCaseDlg(false))
  }
  return (
    <Dialog open={openReopenCaseDlg} onClose={onCancel}>
      <DialogTitle>Reopen Case</DialogTitle>
      <DialogContent>
        <p> AI presetup is finished correctly. </p>{" "}
        <p> Do you want to re-open the case?</p>
      </DialogContent>
      <DialogActions>
        {/* <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button> */}
        <Button variant="contained" onClick={onReopenOK}>
          Reopen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
