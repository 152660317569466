import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { wasmModule } from "../wasm/wasmModule";
import JSZip from "jszip";

export enum SetupType {
  Full = 0, // All teeth
  Simple, // Molar to molar
  Anterior, // 5x5
  Ant3x3, // 3x3
  AlignmentOnly, // Full treatment.
  Aligner5, // Anterior only and minimum root movement.
  Init, // All teeth, OJ/OB not improved
  FineTune, // All teeth, minor adjustment
  COPASimple, // Molar to molar and minimum root movement
  COPAModerate, // Molar to molar and minimum posterior movement
  FullNoAP, // All teeth but no AP control / change.
}

export enum ArchformType {
  Natural = 0,
  Tapered,
  Ovoid,
  Square,
}

export enum TreatArchType {
  Both = 0,
  UpperOnly,
  LowerOnly,
}
export enum MidlineType {
  MIDTYPE_INIT = 0, // to initial value
  MIDTYPE_CENTER, // to center
  MIDTYPE_TOUPPER, // to upper arch initial
  MIDTYPE_TOLOWER, // to lower arch initial
}
export enum OJOBType {
  Init = 0,
  Improve, // 0 mm
  OneMmSpace,
  QuaterMmSpace,
  HalfMmSpace,
}

export enum IPRRange {
  AntOnly = 0,
  Full,
  IncisorOnly,
  CanineOnly,
}
interface AutoSetupSliderParameter {
  name: string;
  min: number;
  max: number;
}
class GDSPlusSetup {
  sliderParamMap: AutoSetupSliderParameter[] = [
    { name: "ArchformAnt", min: 20, max: 40 },
    { name: "ArchformMid", min: 0.786, max: 0.866 },
    { name: "ArchformPost", min: 40, max: 60 },
    { name: "ArchformExpLeft", min: 0.9, max: 1.1 },
    { name: "ArchformExpRight", min: 0.9, max: 1.1 },

    { name: "JawMoveLR", min: -3, max: 3 },
    { name: "JawMoveMD", min: -3, max: 3 },
    { name: "JawMoveUD", min: -3, max: 3 },

    { name: "OJ", min: 1, max: 5 },
    { name: "OB", min: 0.5, max: 5 },
    { name: "LL", min: -1, max: 5 },

    { name: "MidlineUpper", min: -4, max: 4 },
    { name: "MidlineLower", min: -4, max: 4 },
    { name: "AntInclUpper", min: -10, max: 20 },
    { name: "AntInclLower", min: -20, max: 10 },

    { name: "IPRToPrev", min: 0, max: 2 },
    { name: "SpaceToPrev", min: 0, max: 20 },
    { name: "IPRToNext", min: 0, max: 2 },
    { name: "SpaceToNext", min: 0, max: 20 },

    { name: "TMJMoveLimit", min: 0, max: 4 },
  ]

  SwitchAutoSetupModule(isOpen: boolean) {
    if (!wasmModule.isInit) {
      return
    }
    wasmModule.moduleManager.SwitchAutoSetupModule(isOpen)
  }

  getAutoSetupModule() {
    if (!wasmModule.isInit) {
      return undefined
    }
    const autoSetupModule = wasmModule.moduleManager.GetAutoSetupModule()
    return autoSetupModule
  }

  setupByType = (type: SetupType, callback: () => void) => {
    const autoSetupModule = this.getAutoSetupModule()
    autoSetupModule.SetupByTypeID(type)
    stagingManager.updateStagesFromWasm()
    callback()
  }

  getSetupStepMsg = (step: number) => {
    const autoSetupModule = this.getAutoSetupModule()
    return autoSetupModule.GetSetupStepMsg(step)
  }

  setSetupType = (type: SetupType) => {
    const autoSetupModule = this.getAutoSetupModule()
    autoSetupModule.SetSetupType(type)
  }

  runSetupStep = (step: number) => {
    const autoSetupModule = this.getAutoSetupModule()
    const nextStep = autoSetupModule.RunSetupStep(step)
    return nextStep
  }

  setupByRxString = (rxStr: string, callback: () => void) => {
    const autoSetupModule = this.getAutoSetupModule()
    autoSetupModule.SetupByRxString(rxStr)
    stagingManager.updateStagesFromWasm()
    callback()
  }

  SetArchformTypeByID = (type: ArchformType, callback: () => void) => {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      callback()
      return
      // return {
      //   ArchformAnt: { lableValue: 0, sliderValue: 0 },
      //   ArchformMid: { lableValue: 0, sliderValue: 0 },
      //   ArchformPost: { lableValue: 0, sliderValue: 0 },
      // };
    }
    autoSetupModule.SetArchformTypeByID(type)
    stagingManager.updateStagesFromWasm()

    callback()

    const ArchformAnt = this.GetParamDouble("ArchformAnt")
    const ArchformMid = this.GetParamDouble("ArchformMid")
    const ArchformPost = this.GetParamDouble("ArchformPost")
    const ArchformExpLeft = this.GetParamDouble("ArchformExpLeft")
    const ArchformExpRight = this.GetParamDouble("ArchformExpRight")
    return {
      ArchformAnt: ArchformAnt,
      ArchformMid: ArchformMid,
      ArchformPost: ArchformPost,
      ArchformExpLeft: ArchformExpLeft,
      ArchformExpRight: ArchformExpRight,
    }
  }
  SetTreatArchTypeID = (type: TreatArchType, callback: () => void) => {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      callback()
      return
    }
    autoSetupModule.SetTreatArchTypeID(type)
    callback()
  }

  // ArchformAnt  ArchformMid  ArchformPost ArchformExpLeft, ArchformExpRight OJ    OB
  GetParamDouble(sliderName: string) {
    const autoSetupModule = this.getAutoSetupModule()

    if (!autoSetupModule) return { lableValue: 0, sliderValue: 0 }

    const slider = this.sliderParamMap.find((x) => {
      return x.name === sliderName
    })
    if (!slider) return { lableValue: 0, sliderValue: 0 }

    const param = autoSetupModule.GetParamDouble(sliderName)

    const pos = ((param - slider.min) * 100.0) / (slider.max - slider.min)

    let sliderlableVal: number = param
    if (sliderName === "ArchformMid") sliderlableVal = sliderlableVal * 100

    return { lableValue: sliderlableVal, sliderValue: pos }
  }

  GetSliderLableValue(sliderName: string): number {
    const autoSetupModule = this.getAutoSetupModule()

    if (!autoSetupModule) return 0

    const slider = this.sliderParamMap.find((x) => {
      return x.name === sliderName
    })
    if (!slider) return 0

    const param = autoSetupModule.GetParamDouble(sliderName)

    if (sliderName === "ArchformMid") return param * 100
    else return param
  }

  GetParamValue(paramName: string) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return 0
    return autoSetupModule.GetParamDouble(paramName)
  }

  SetParamValue(paramName: string, value: number) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return
    autoSetupModule.SetParamDouble(paramName, value)
    stagingManager.updateStagesFromWasm()
  }

  SetParamDouble(sliderName: string, sliderValue: number) {
    console.log("SetParamDouble:" + sliderName + ":" + sliderValue)
    const autoSetupModule = this.getAutoSetupModule()

    if (!autoSetupModule) return

    const slider = this.sliderParamMap.find((x) => {
      return x.name === sliderName
    })
    if (!slider) return

    const param = (sliderValue / 100.0) * (slider.max - slider.min) + slider.min

    autoSetupModule.SetParamDouble(sliderName, param)

    stagingManager.updateStagesFromWasm()
  }

  SetParamDoubleValue(sliderName: string, value: number) {
    console.log("SetParamDouble:" + sliderName + ":" + value)
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return
    const slider = this.sliderParamMap.find((x) => {
      return x.name === sliderName
    })
    if (!slider) return
    autoSetupModule.SetParamDouble(sliderName, value)
    stagingManager.updateStagesFromWasm()
  }

  ResolveOccColl(
    postOnly: boolean,
    checkSelTeeth: boolean,
    callback: () => void,
  ) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      callback()
      return
    }

    autoSetupModule.ResolveOccColl(postOnly, checkSelTeeth)

    stagingManager.updateStagesFromWasm()

    callback()
  }

  /**
   * 4 button function of  Overjet/Overbite in Anterior
   * @param type
   * @param callback
   * @returns
   */
  SetOJOBByTypeID(type: OJOBType, callback: () => void) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      callback()
      return
    }

    autoSetupModule.SetOJOBByTypeID(type)
    callback()

    const OJ = this.GetParamDouble("OJ")
    const OB = this.GetParamDouble("OB")
    const LL = this.GetParamDouble('"LL')
    return {
      OJ: OJ,
      OB: OB,
      LL: LL,
    }
  }
  ResetArchShift() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return
    autoSetupModule.ResetArchShift()
  }

  SetMidlineByTypeID = (type: MidlineType, callback: () => void) => {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      callback()
      return
    }
    autoSetupModule.SetMidlineByTypeID(type)
    callback()

    const MidlineUpper = this.GetParamDouble("MidlineUpper")
    const MidlineLower = this.GetParamDouble("MidlineLower")
    return {
      MidlineUpper: MidlineUpper,
      MidlineLower: MidlineLower,
    }
  }
  SetMidlineEnable(isUpper: boolean, enable: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetMidlineEnable(isUpper, enable)
  }

  SetupAPUpperToLower() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetupAPUpperToLower()

    const upper = this.GetSideAPPosAndEnable(true)
    const lower = this.GetSideAPPosAndEnable(false)

    return {
      upper: upper,
      lower: lower,
    }
  }

  NoAPControl() {
    this.EnableSideAP(true, true, false)
    this.EnableSideAP(true, false, false)
    this.EnableSideAP(false, true, false)
    this.EnableSideAP(false, false, false)

    const upper = this.GetSideAPPosAndEnable(true)
    const lower = this.GetSideAPPosAndEnable(false)

    return {
      upper: upper,
      lower: lower,
    }
  }

  SetSideAP(upper: boolean, rightSide: boolean, pos: number) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetSideAP(upper, rightSide, Math.ceil(pos))
  }

  EnableSideAP(upper: boolean, rightSide: boolean, enable: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.EnableSideAP(upper, rightSide, enable)
  }

  SetToothAPEnable(enable: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetToothAPEnable(enable)
  }

  SetToothAP(pos: number) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetToothAP(Math.ceil(pos))
  }

  SetIPRRangeByID(upper: boolean, range: IPRRange) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetIPRRangeByID(upper, range)
  }

  SetSelectedToothIPR(pos: number, isSpace: boolean, toNext: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    if (isSpace) pos = -pos
    autoSetupModule.SetSelectedToothIPR(pos, toNext)
  }
  SetToothIPREnable(enable: boolean, toNext: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetToothIPREnable(enable, toNext)
  }

  GetMidlineEnable(upper: boolean): boolean {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return false
    }
    return autoSetupModule.GetMidlineEnable(upper)
  }

  GetToothIRPAndSpace(toNext: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return { enable: false, space: 0, ipr: 0 }
    }
    const enable = this.GetToothIPREnable(toNext)
    const value = this.GetSelectedToothIPRValue(toNext)
    return { enable: enable, space: value, ipr: -value }
  }

  ResolveAllColl(callback: () => void) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      callback()
      return
    }
    autoSetupModule.ResolveAllColl()
    stagingManager.updateStagesFromWasm()
    callback()
  }

  private GetSelectedToothIPRValue(toNext: boolean): number {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return 0
    }
    return autoSetupModule.GetSelectedToothIPRValue(toNext)
  }

  GetToothIPREnable(toNext: boolean): boolean {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return false
    }
    return autoSetupModule.GetToothIPREnable(toNext)
  }

  GetToothAPAEnableAndValue() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return { apEnable: false, apValue: 0 }
    }
    const enable = this.GetToothAPEnable()
    const value = this.GetToothAPPosValue()

    return { apEnable: enable, apValue: value }
  }

  private GetToothAPEnable(): boolean {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return false
    }
    return autoSetupModule.GetToothAPEnable()
  }

  private GetToothAPPosValue(): number {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return 0
    }
    return autoSetupModule.GetToothAPPosValue()
  }

  GetSideAPPosAndEnable(isUpper: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return {
        rightEnable: false,
        rightValue: 0,
        leftEnable: false,
        leftValue: 0,
      }
    }
    const rightPos = this.GetSideAPPos(isUpper, true)
    const leftPos = this.GetSideAPPos(isUpper, false)

    const rightEnable = this.GetEnableSideAP(isUpper, true)
    const leftEnable = this.GetEnableSideAP(isUpper, false)

    return {
      rightEnable: rightEnable,
      rightValue: rightPos,
      leftEnable: leftEnable,
      leftValue: leftPos,
    }
  }
  ExtractTooth() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return
    autoSetupModule.PullOutTeeth()
    stagingManager.updateStagesFromWasm()
  }
  RestoreAllTeeth() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return
    autoSetupModule.RestoreAllTeeth()
    stagingManager.updateStagesFromWasm()
  }
  SetToothFixed(tid: number, fixed: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return
    autoSetupModule.SetToothFixed(tid, fixed)
    stagingManager.updateStagesFromWasm()
  }

  SetLowerGlobalMovement(autoOrRemove: boolean, biteJump: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return
    autoSetupModule.SetLowerGlobalMovement(autoOrRemove, biteJump)
    stagingManager.updateStagesFromWasm()
  }

  GetTMJMoveDist(leftSide: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) return 0.0
    return autoSetupModule.GetTMJMoveDist(leftSide)
  }

  private GetSideAPPos(upper: boolean, rightSide: boolean): number {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return 0
    }
    return autoSetupModule.GetSideAPPos(upper, rightSide)
  }

  private GetEnableSideAP(upper: boolean, rightSide: boolean): boolean {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return false
    }
    return autoSetupModule.GetEnableSideAP(upper, rightSide)
  }

  SetShowArchform(show: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.SetShowArchform(show)
  }

  EnableAutoOccContact(enable: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.EnableAutoOccContact(enable)
  }

  RunRetouchV2() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.RunRetouchV2("/test/prevcase", false)
  }
  ShowCourseCheckScans(toShow: boolean) {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.ShowCourseCheckScans(toShow)
  }

  RunCourseCheck() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return
    }
    autoSetupModule.RunCourseCheck()
  }

  GetCourseCheckResult() {
    const autoSetupModule = this.getAutoSetupModule()
    if (!autoSetupModule) {
      return ""
    }
    return autoSetupModule.GetCourseCheckResult()
  }

  DownloadScanStlZip(zipFileName: string) {
    if (!wasmModule.isInit) {
      console.log("WASM is not inited")
      return
    }
    const preRoot = "test/pre/"
    wasmModule.statusController.SaveScanStl(preRoot)
    const scanU = wasmModule.module.FS.readFile(preRoot + "scan_u.stl")
    const scanL = wasmModule.module.FS.readFile(preRoot + "scan_l.stl")
    let zip = new JSZip()
    zip.file("scan_u.stl", scanU)
    zip.file("scan_l.stl", scanL)
    zip.generateAsync({ type: "blob" }).then(
      function (blob) {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.setAttribute("style", "display: none")
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = zipFileName
        a.click()
        window.URL.revokeObjectURL(url)
        console.log("Download file:" + zipFileName)
      },
      function (err) {
        console.log("error to save zip file...")
      },
    )
  }
  DownloadTeethStlZip(zipFileName: string) {
    if (!wasmModule.isInit) {
      console.log("WASM is not inited")
      return
    }
    const teethDir = "test/pre/teeth/"
    if (!wasmModule.module.FS.analyzePath(teethDir).exists)
      wasmModule.module.FS.mkdir(teethDir)

    wasmModule.statusController.SaveTeethStl(teethDir)
    const teeth = wasmModule.module.FS.readdir(teethDir)
    let zip = new JSZip()
    for (let i = 0; i < teeth.length; i++) {
      const toothfile = teeth[i]
      if (toothfile === "." || toothfile === "..") continue
      const file = wasmModule.module.FS.readFile(teethDir + toothfile)
      zip.file(toothfile, file)
    }
    zip.generateAsync({ type: "blob" }).then(
      function (blob) {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.setAttribute("style", "display: none")
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = zipFileName
        a.click()
        window.URL.revokeObjectURL(url)
        console.log("Download file:" + zipFileName)
      },
      function (err) {
        console.log("error to save zip file...")
      },
    )
  }

  DownloadArchStlZip(zipFileName: string) {
    if (!wasmModule.isInit) {
      console.log("WASM is not inited")
      return
    }
    const preRoot = "test/pre/"
    wasmModule.statusController.SaveArchStl(preRoot)
    const allArches = wasmModule.module.FS.readdir(preRoot)
    let zip = new JSZip()
    for (let i = 0; i < allArches.length; i++) {
      const toothfile = allArches[i]
      if (toothfile === "." || toothfile === "..") continue
      const file = wasmModule.module.FS.readFile(preRoot + toothfile)
      zip.file(toothfile, file)
    }
    zip.generateAsync({ type: "blob" }).then(
      function (blob) {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.setAttribute("style", "display: none")
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = zipFileName
        a.click()
        window.URL.revokeObjectURL(url)
        console.log("Download file:" + zipFileName)
      },
      function (err) {
        console.log("error to save zip file...")
      },
    )
  }

  DownloadFinishedArchStlZip(zipFileName: string) {
    if (!wasmModule.isInit) {
      console.log("WASM is not inited")
      return
    }
    const preRoot = "test/pre/"
    const allArches = wasmModule.module.FS.readdir(preRoot)
    let zip = new JSZip()
    for (let i = 0; i < allArches.length; i++) {
      const toothfile = allArches[i]
      if (toothfile === "." || toothfile === "..") continue
      const file = wasmModule.module.FS.readFile(preRoot + toothfile)
      zip.file(toothfile, file)
    }
    zip.generateAsync({ type: "blob" }).then(
      function (blob) {
        let a = document.createElement("a")
        document.body.appendChild(a)
        a.setAttribute("style", "display: none")
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = zipFileName
        a.click()
        window.URL.revokeObjectURL(url)
        console.log("Download file:" + zipFileName)
      },
      function (err) {
        console.log("error to save zip file...")
      },
    )
  }

  UploadTeethTemplate(zipFile: File) {
    if (!wasmModule.isInit) {
      console.log("WASM is not inited")
      return
    }
    const teethDir = "test/pre/template/"
    if (!wasmModule.module.FS.analyzePath(teethDir).exists)
      wasmModule.module.FS.mkdir(teethDir)
    let jsZip = new JSZip()
    jsZip.loadAsync(zipFile).then(function (zip) {
      Object.keys(zip.files).forEach(function (filename) {
        zip
          .file(filename)
          .async("uint8array")
          .then(function (data) {
            wasmModule.module.FS.writeFile(teethDir + filename, data) // Save to WASM FS
          })
      })
    })
  }
  UpdateProgressCallback?: (msg: string, percent: number) => void
}



export const gdsPlusSetup = new GDSPlusSetup();
