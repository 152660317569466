import { createAsyncThunk } from "@reduxjs/toolkit"
import { store } from "../../store"
import { fetchZipsFromCacheOrRemote } from "./clinicalThunkApi"
import JSZip from "jszip"
import { 
  setOpenMode,
  setProgressMsg
} from "./clinicalSlice"
import {
  setHasUploadSTLs,
  setIsCasePreview,
  setReportData,
  setStageDataLen,
  setPlanList,
  setSingleArchBool,
} from "@/UDTreat/udTreatSlice"
import {
  caseManagement,
  wasmModule,
  stageControl,
  attachmentModule,
  resourcesSynchronization,
  EArchType,
  preview,
} from "@/gluelayer"

async function checkIsCaseFull(zipList: Record<string, Blob>,isShowDebugLog = false) {
  const log = isShowDebugLog ? console.log : () => {}
  const blob2 = zipList["bulk2.zip"]
  log("XY: Blob2 check - Exists:", !!blob2, "Size:", blob2?.size)

  if (blob2) {
    log("XY: Processing bulk2 file...")
    const arrayBuffer = await blob2.arrayBuffer()
    const zip = new JSZip()
    const loadedZip = await zip.loadAsync(arrayBuffer)
    log("XY: ZIP file loaded for bulk2")

    // 读取 ModuleControl.data 文件
    const targetFileName = "Setting Data2/ModuleControl.data"
    const entry = loadedZip.files[targetFileName]

    if (entry && !entry.dir) {
      const data = await entry.async("string")
      const lines = data.split("\n")

      let newVersionFile = false
      let numberOfProcessModule = 0
      let closedModuleCount = 0

      // 检查文件内容
      if (lines.length > 2 && /^\d+$/.test(lines[2].trim())) {
        newVersionFile = true
        numberOfProcessModule = parseInt(lines[2].trim())
      } else {
        numberOfProcessModule = parseInt(lines[1].trim())
      }

      if (numberOfProcessModule === 0) {
        return false // 没有模块
      }

      log("XY: numberOfProcessModule is ", numberOfProcessModule)

      // 处理模块信息
      for (let i = 0; i < numberOfProcessModule; i++) {
        // 读取状态行
        const moduleStatusLine = lines[newVersionFile ? 3 + i * 5 : 2 + i * 4]
        log(`XY: Module ${i} - Status: ${moduleStatusLine}`)

        // 读取名称行
        const moduleNameLine = lines[newVersionFile ? 4 + i * 5 : 3 + i * 4]
        log(`XY: Module ${i} - Name: ${moduleNameLine}`)

        // 读取下一个行（状态值）
        const nextLine = lines[newVersionFile ? 5 + i * 5 : 4 + i * 4]
        log(`XY Next Line after Module ${i}: "${nextLine}"`)

        // 解析状态值
        const statusValue = parseInt(nextLine.trim())

        if (statusValue > 0) {
          closedModuleCount++
        }
        log(
          "XY: After closedModuleCount is ",
          closedModuleCount,
          " i is ",
          i,
        )

        // 检查是否所有模块都关闭
        if (closedModuleCount === numberOfProcessModule) {
          log("XY: IS goto setup")
          return true // 所有模块都关闭
        }

        // 读取额外的行（如果是新版本文件）
        if (newVersionFile) {
          const extraLine1 = lines[6 + i * 5]
          const extraLine2 = lines[7 + i * 5]
          const extraLine3 = lines[8 + i * 5]
          log(`XY: Final Line 1 for Module ${i}: "${extraLine1}"`)
          log(`XY: Final Line 2 for Module ${i}: "${extraLine2}"`)
          // 处理额外行的逻辑（如果需要）
        } else {
          const extraLine3 = lines[5 + i * 4]
          // 处理额外行的逻辑（如果需要）
        }
      }
    } else {
      log(`XY: ${targetFileName} not found in the ZIP file.`)
    }
  } else {
    log("XY: bulk2 does not exist in zipList.")
  }
  return false
}

/**
 * workflow to open case in preview mode
 * @param zips
 * @param callback
 */
const openCasePreview = async (
  zips: Record<string, Blob>,
  callback?: () => void,
) => {
  const dispatch = store.dispatch
  caseManagement.closeCase()
  await preview.initScanView(
    document.getElementById("canvas") as HTMLCanvasElement,
    zips as Record<string, File>,
  )

  const res = preview.drawScanMtc([0.25, 4])

  const originArchTypeData = resourcesSynchronization.getFileDirectly(
    "ArchType.json",
    "Setting Data2",
    "utf8",
  )
  console.log("🚀 ~ openCasePreview ~ originArchTypeData:", originArchTypeData)
  await dispatch(
    setHasUploadSTLs([
      res.get("arch_o_u.mtc") !== null,
      res.get("arch_o_l.mtc") !== null,
    ]),
  )

  callback && callback()
}

/**
 * workflow to open case in setup mode
 * @param zips
 * @param callback
 */
const openCaseFull = async (
  zipList: Record<string, Blob>,
  callback?: () => void,
) => {
  const dispatch = store.dispatch

  // get report data
  const getReportFunc = () => {
    const { attachment, toothlist, ipr } =
      caseManagement.getAttachmentIPRReport()

    const { lowlist, uplist } = toothlist

    const list = lowlist.concat(uplist)
    dispatch(
      setReportData({
        attachment,
        toothlist: list,
        iprData: ipr,
      }),
    )
  }

  // close case befroe open
  caseManagement.closeCase()

  await caseManagement.openCaseWithPromise(
    document.getElementById("canvas") as HTMLCanvasElement,
    zipList,
  )

  //setPreparation(true)
  caseManagement.setBackgroundPic("backgroundvtk.png") //背景图只需要将图片名字传入，图片存储在resource/Pictures/下
  dispatch(setHasUploadSTLs([true, true]))
  // get txplan data
  const txList = caseManagement.getTreatmentPlanList()
  dispatch(setPlanList(txList))
  // get stage len
  const { jointUpKeypoints, jointLowerKeypoints } =
    stageControl.getWasmStageData()
  dispatch(
    setStageDataLen({
      up: jointUpKeypoints.length,
      low: jointLowerKeypoints.length,
    }),
  )
  // get reportData
  getReportFunc()
  // report update callback function
  caseManagement.setOnReportUpdateCB((data) => {
    const { attachment, toothlist, ipr } = data

    const { lowlist, uplist } = toothlist
    // setAttachmentObj(obj)
    const list = lowlist.concat(uplist)
    dispatch(
      setReportData({
        attachment,
        toothlist: list,
        iprData: ipr,
      }),
    )
  })
  // get single arch bool
  const hasUpper = wasmModule.getArchModel(EArchType.UpArch)
  const hasLower = wasmModule.getArchModel(EArchType.LowArch)
  dispatch(setSingleArchBool(hasUpper == null || hasLower == null))
  dispatch(setIsCasePreview(false))

  callback && callback()
}

export const fetchZipsAndLoadCase = createAsyncThunk(
  "clinicalService/fetchZipsAndLoadCase",
  async (
    { caseId, patientId }: any,
    { rejectWithValue, getState, dispatch },
  ) => {
    try{
      // 1. get zip files
      console.log("openCase.....fetchZipsFromCacheOrRemote")
      const ret:any = await dispatch(fetchZipsFromCacheOrRemote({ caseId, patientId })).unwrap()

      const zipList = (getState() as any).clinicalService.zipList
      const { caseDetail } = (getState() as any).caseService

      console.log("loadCase..... ", zipList, caseDetail)

      // 2. check if it is full case,analysis case OpenMode
      if (Object.keys(zipList).length >= 6) {
        const isFullCase = await checkIsCaseFull(zipList)
        console.log("🚀 ~ checkFullCase.then ~ isFullCase:", isFullCase)
        const caseDisposition = caseDetail.caseDisposition
        const udesign_json = JSON.parse(caseDetail.udesign_json)
        if (
          caseDisposition === "FROM_UDESIGN" &&
          !isFullCase &&
          udesign_json.needPresetup === undefined
        ) {
          await dispatch(setOpenMode("needdopresetupbyudesign"))
          return
        }

        if (isFullCase) {
          dispatch(setOpenMode("setup"))
          dispatch(setProgressMsg("Open full case ..."))
          await openCaseFull(zipList,()=>{
            // set attachment stage after setup case
            const {AttachmentStage} = (getState() as any).userService.clinicalSettings
            attachmentModule.setAttachStep(+AttachmentStage)
          })
        } else {
          dispatch(setOpenMode("preview"))
          dispatch(setProgressMsg("Open preview case ..."))
          await openCasePreview(zipList)
        }
      }else{
        throw new Error("No zip files, abnormal case!")
      }
    }catch(err){
      console.warn("Something wrong in loading case ...")
      return rejectWithValue(err)
    }

  },
)
