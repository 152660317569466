import { createSlice } from "@reduxjs/toolkit"
import { uploadCaseZips,fetchFileZips } from "../clinical/clinicalThunkApi"


interface ICacheServiceData {
  /**
   * key is case id, value is a record of zip file name and blob
   */
  caches: Record<string, Record<string, Blob>>
  loading: "idle" | "pending" | "succeeded" | "failed"
}



const defualtState: ICacheServiceData = {
  caches: {},
  loading: "idle",
}
/**
 * this state is used to store the download files from server,if there is a need to download the file again, it will be downloaded from the cache.
 */
export const cacheServiceSlice = createSlice({
  name: "cacheService",
  initialState: {
    ...defualtState
  },
  reducers:{
    clearCache: (state,action) => {
      if (state.caches[action.payload.caseId]) {
        delete state.caches[action.payload.caseId]
        console.log("clearCache ok",state.caches,action.payload.caseId);
      }
    }
  },
  extraReducers:(builder) => {
    builder.addCase(uploadCaseZips.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadCaseZips.fulfilled, (state, action) => {
      state.loading = "succeeded"
      console.log('uploadaction',action)
      //state.zips = action.payload as Record<string, File>
      const caseId = action.meta.arg.caseId;
      const zips = action.meta.arg.zips;
      if (!state.caches[caseId]) {
        state.caches[caseId] = {} as Record<string, Blob>;
      }
      for (let i = 0; i < zips.length; i++) {
        const zipInfo = zips[i];
        state.caches[caseId][zipInfo.fileName]= zipInfo.file;
      }
    })
    builder.addCase(uploadCaseZips.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(fetchFileZips.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchFileZips.fulfilled, (state,action) => {
      state.loading = "succeeded"
      const payload = action.payload as any[];
      console.log("fetchFileZips.fulfilled222",action.payload,action)
      const files:Record<string,Blob> ={} 
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i];
        const data = item.data;
        const respUrl = item.request.responseURL;
        const fileName = respUrl.split("/").pop();
        files[fileName] = data;
      }
      const caseId = action.meta.arg.caseId;
      //which case to store the zips
      if (!state.caches[caseId]) {
        state.caches[caseId] = {} as Record<string, Blob>;
      }
      state.caches[caseId] = files;

    })
    builder.addCase(fetchFileZips.rejected, (state) => {
      state.loading = "failed"
    })

  }
})

export const {
  clearCache
} = cacheServiceSlice.actions