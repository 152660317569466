import { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined"

import { UText } from "../../components"
import {
  Box,
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "../../components/mui.components"
import PatientInfoHeader from "../../containers/LandingLayout/PatientInfoHeader/PatientInfoHeader"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import {
  resetAction,
  resetCase,
  setClinicalData,
  setIsScansRendered,
} from "../../core/app/slices/clinical/clinicalSlice"
import { downloadPhotographs1_1 } from "@/core/app/slices/records/photograph/photographThunkApi"
import { uploadProfilePhoto } from "@/core/app/slices/records/xrays/xraysThunkApi"
import { setIsRouteChangeBlocked } from "../../core/app/slices/navigationPopup/navigationPopupSlice"
import { fetchFilesList } from "../../core/app/slices/records/photograph"
import {
  resetPhotoLists,
  setPhotoTypeJson,
} from "../../core/app/slices/records/photograph/photographSlice"
import { resetXrays } from "../../core/app/slices/records/xrays/xraysSlice"
import { RootState } from "../../core/app/store"
import { useMyNavigation } from "../../hooks/useMyNavigation"

import Photograph from "./Photograph/Photograph"
import Scans from "./Scans/Scans"
import Xrays from "./Xrays/Xray"
import DentalMoudle from "./Dental/dental"
import RxFormMoudle from "./RxForm/rxForm"
import { updateCaseStatus } from "@/core/app/slices/case/caseThunkApi"
import { CASE_STATUS } from "../Patient/config/status.config"
import { checkIsThereASpecifiedPhoto } from "@/UDTreat/left/utils"
import { setCaseRAtype } from "@/UDTreat/udTreatSlice"

export interface IRecordRoutesProps {
  showError: boolean
  xrayErrors: boolean
  scanErrors: boolean
  photographErrors: boolean
  setScanErrors: (value: boolean) => void
  setPhotographErrors: (value: boolean) => void
  setXrayErrors: (value: boolean) => void
  flagNextBtn: boolean
  steps?: { id: string; lable: string; to: string }[]
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  isDragOpen: boolean
  setIsDragOpen: (value: boolean) => void
  handleSkip: () => void
  dentalGoNext: boolean | undefined
  setDentalGoNext: (arg: undefined | boolean) => void
  needDoPresetup: boolean
  setNeedDoPresetup: (value: boolean) => void
}

const RecordRoutes: FC<IRecordRoutesProps> = ({
  showError,
  xrayErrors,
  scanErrors,
  photographErrors,
  setScanErrors,
  setPhotographErrors,
  setXrayErrors,
  flagNextBtn,
  steps,
  setIsDragEmpty,
  isAlertOpen,
  setIsAlertOpen,
  isDragOpen,
  setIsDragOpen,
  handleSkip,
  dentalGoNext,
  setDentalGoNext,
  needDoPresetup,
  setNeedDoPresetup,
}) => {
  const { t } = useTranslation("common")
  return (
    <Routes>
      <Route
        path={"scans"}
        element={
          <Scans
            setScanErrors={setScanErrors}
            flagClickNext={flagNextBtn}
            steps={steps}
            handleSkip={handleSkip}
            caseType={"new_scan"}
            needDoPresetup={needDoPresetup}
            setNeedDoPresetup={setNeedDoPresetup}
          />
        }
      />
      <Route
        path={"photograph"}
        element={
          <Photograph
            title={t("records.photograph.title")}
            setPhotographErrors={setPhotographErrors}
            setIsDragEmpty={setIsDragEmpty}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            setIsDragOpen={setIsDragOpen}
            isDragOpen={isDragOpen}
            setNeedDoPresetup={setNeedDoPresetup}
          />
        }
      />
      <Route
        path={"xray"}
        element={
          <Xrays
            title={t("records.xray.title")}
            showError={showError}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            xrayErrors={xrayErrors}
            setXrayErrors={setXrayErrors}
          />
        }
      />
      <Route
        path={"dentalchart"}
        element={
          <DentalMoudle
            dentalGoNext={dentalGoNext}
            setDentalGoNext={setDentalGoNext}
          />
        }
      />
      <Route path={"rxForm/*"} element={<RxFormMoudle />} />
      <Route path={"*"} element={<Navigate to={"scans"} replace />} />
    </Routes>
  )
}

const RecordModule: FC = () => {
  // order type
  const { caseRAtype } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { patientId, caseId } = useParams()
  const [recordsStepper, setRecordsStepper] = useState([])
  const navigate = useMyNavigation()
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()

  const [activeStep, setActiveStep] = useState<number>(0)
  const {
    isPhotoUploading,
    fileList,
    compositePhoto,
    individualPhotos,
    isDownloading,
    count,
    individualPhotosCount,
    photoTypeJson,
  } = useAppSelector((state: RootState) => state.PhotosService)
  const { loading: xrayLoading, xrayUploadLoading } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { preferences } = useAppSelector(
    (state: RootState) => state.userService?.user,
  )
  const { isScansRendered } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [skipped, setSkipped] = useState(new Set<number>())
  const [flagNextBtn, setFlagNextBtn] = useState()
  const [isDragOpen, setIsDragOpen] = useState<boolean>(false)
  const [needDoPresetup, setNeedDoPresetup] = useState<boolean>(false)

  const location = useLocation()

  useEffect(() => {
    switch (caseRAtype) {
      case "retainer":
        setRecordsStepper([
          {
            id: "scans",
            label: "Scans",
            to: `/records/patient/${patientId}/case/${caseId}/scans`,
          },
          {
            id: "photographs",
            label: "Photos & X-Rays",
            to: `/records/patient/${patientId}/case/${caseId}/photograph`,
          },
          {
            id: "rx",
            label: "Prescription",
            to: `/records/patient/${patientId}/case/${caseId}/rxForm`,
          },
        ])
        break
      case "alinger":
        setRecordsStepper([
          {
            id: "scans",
            label: "Scans",
            to: `/records/patient/${patientId}/case/${caseId}/scans`,
          },
          {
            id: "photographs",
            label: "Photos & X-Rays",
            to: `/records/patient/${patientId}/case/${caseId}/photograph`,
          },
          {
            id: "dental",
            label: "Dental Chart",
            to: `/records/patient/${patientId}/case/${caseId}/dentalchart`,
          },
          {
            id: "rx",
            label: "Prescription",
            to: `/records/patient/${patientId}/case/${caseId}/rxForm`,
          },
        ])
        break
      case "selfplan":
        setRecordsStepper([
          {
            id: "scans",
            label: "Scans",
            to: `/records/patient/${patientId}/case/${caseId}/scans`,
          },
          {
            id: "photographs",
            label: "Photos & X-Rays",
            to: `/records/patient/${patientId}/case/${caseId}/photograph`,
          },
        ])
        break
    }
  }, [caseRAtype])

  useEffect(() => {
    setFlagNextBtn(undefined)
    if (location?.pathname.includes("/scans")) {
      setActiveStep(0)
    } else if (location?.pathname.includes("/photograph")) {
      setActiveStep(1)
    } else if (location?.pathname.includes("/dental")) {
      setActiveStep(2)
    } else if (location?.pathname.includes("/rxForm")) {
      caseRAtype === "retainer" ? setActiveStep(2) : setActiveStep(3)
    } else {
      setActiveStep(0)
    }
  }, [location, caseRAtype])

  useEffect(() => {
    dispatch(resetCase())
    dispatch(resetXrays())
    dispatch(resetPhotoLists())
    dispatch(setPhotoTypeJson(null))
  }, [dispatch])

  const [scanErrors, setScanErrors] = useState<boolean>(false)
  const [photographErrors, setPhotographErrors] = useState<boolean>(false)
  const [xrayErrors, setXrayErrors] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [isDragEmpty, setIsDragEmpty] = useState<boolean>(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const avatarFileName = useRef(null)
  const isStepOptional = (step: number) => {
    if (scanErrors && step === 0) {
      return true
    }
    // if (photographErrors && step === 1) {
    //   return true
    // }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }
  const handleNext = async () => {
    if (
      isDragEmpty &&
      isDragOpen &&
      preferences &&
      !preferences?.on_show_sort_warning
    ) {
      setIsAlertOpen(true)
    } else if (individualPhotos.length > 0 && !isDragOpen) {
      setIsDragOpen(true)
    } else if (activeStep === 1) {
      const { normalPosition, xrayPosition } =
        checkIsThereASpecifiedPhoto(photoTypeJson)
      if ((photographErrors || scanErrors) && caseRAtype !== "retainer") {
        setShowError(true)
      } else if (
        (!normalPosition || !xrayPosition) &&
        caseRAtype !== "retainer"
      ) {
        setPhotographErrors(true)
        setShowError(true)
      } else {
        if (activeStep === 1) {
          // set avatar when finish setting photos.
          if (photoTypeJson) {
            await setAvatarFromJson({ ...photoTypeJson } as any)
          }
        }
        if (caseRAtype === "retainer") {
          // 跳过了dental，需要将caseDisposition 改为FILL_OUT_RX_FORM
          dispatch(
            updateCaseStatus({
              patientId,
              caseId,
              payload: { case_disposition: CASE_STATUS.FILL_OUT_RX_FORM },
            }),
          ).then((res) => {
            let newSkipped = skipped
            if (isStepSkipped(activeStep)) {
              newSkipped = new Set(newSkipped.values())
              newSkipped.delete(activeStep)
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            setSkipped(newSkipped)
            navigate(
              `/records/patient/${patientId}/case/${caseId}/rxForm/retainer`,
            )
          })
        } else {
          let newSkipped = skipped
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values())
            newSkipped.delete(activeStep)
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
          setSkipped(newSkipped)
          navigate(recordsStepper[activeStep + 1].to)
        }
      }
    } else {
      // dental save and go rx
      if (activeStep === 2) {
        setDentalGoNext(!dentalGoNext)
        let newSkipped = skipped
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values())
          newSkipped.delete(activeStep)
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped(newSkipped)
      }
    }
  }

  /**
   * if there is a smile-photo or a front-photo when creating case,set avatar.
   * @param typeJson
   */
  const setAvatarFromJson = async (typeJson: Object) => {
    const keys = Object.keys(typeJson)
    let smilePhoto: string | undefined, frontPhoto: string | undefined
    for (const key of keys) {
      if (key === "thumbs") {
        continue
      }
      if (typeJson[key] === "front_smile") {
        smilePhoto = key
      } else if (typeJson[key] === "front_normal") {
        frontPhoto = key
      }
    }

    // if there is a smile-photo,upload smile-photo,
    // else there is a front-photo,then upload;
    let uploadAvatar: string | undefined
    if (smilePhoto) {
      uploadAvatar = smilePhoto
    } else if (frontPhoto) {
      uploadAvatar = frontPhoto
    }

    // console.log('111111frontPhoto:::',uploadAvatar);

    if (uploadAvatar && uploadAvatar !== avatarFileName.current) {
      const ret = await dispatch(
        downloadPhotographs1_1({
          patientId,
          caseId,
          fileNames: [uploadAvatar],
        }),
      )
      // console.log('22222222frontPhoto:::',ret)
      if (ret.payload) {
        let file = ret.payload[uploadAvatar]
        const formData = new FormData()
        formData.append("attachment", file)
        // console.log('33333frontPhoto:::',file)
        const uploadAvatarRet = await dispatch(
          uploadProfilePhoto({ patientId, formData, fileName: file.name }),
        )
        avatarFileName.current = uploadAvatar
        // console.log('44444frontPhoto:::',uploadAvatarRet,avatarFileName.current)
      }
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    navigate(recordsStepper[activeStep - 1].to)
  }

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    navigate(recordsStepper[activeStep + 1].to)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const isStepFailed = (step: number) => {
    if (showError) {
      if (photographErrors && step === 1) {
        return true
      }
      if (scanErrors && step === 0) {
        return true
      }
      // if (xrayErrors && step === 2) {
      //   return true
      // }
    }
  }

  // useEffect(() => {
  //   if (
  //     compositePhoto.length === 0 &&
  //     individualPhotos.length === 0 &&
  //     !fileList.find((file) => file.file_name.includes("composite")) &&
  //     !fileList.find((file) => file.file_name.includes("individual"))
  //   ) {
  //     setPhotographErrors(true)
  //   } else {
  //     setPhotographErrors(false)
  //   }
  //   if (pano.length === 0) {
  //     setXrayErrors(true)
  //   } else {
  //     setXrayErrors(false)
  //   }
  // }, [compositePhoto, individualPhotos, pano, fileList])
  //Clean up clincal zipNames, zipList, and other data to avoid entering clincal with errors
  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetAction())
    }
  }, [])
  useEffect(() => {
    // Early return if case ID is missing

    if (!caseDetail?.id) return
    // Navigate to choosetype if on /scans and udesign_json is absent
    const isScansPath = location.pathname.includes("/scans")
    const isUdesignMissing = !caseDetail?.udesign_json
    if(!isUdesignMissing){
      const udesignJson = JSON.parse(caseDetail.udesign_json)
      
      if(udesignJson.UpperType || udesignJson.LowerType){
        dispatch(setCaseRAtype(
          [udesignJson.UpperType, udesignJson.LowerType].includes("A")
          ? "alinger"
          : "retainer",
        ))
      }
    }

    // Return if scans are already rendered
    if(isScansRendered) return

    if (isScansPath && isUdesignMissing && !caseRAtype) {
      navigate(`/patients/choosetype/patient/${patientId}/case/${caseId}`)
    } else if (isScansPath && !caseRAtype && !isUdesignMissing) {
      dispatch(setIsScansRendered(true))
      
    } else if (isScansPath) {
      // Set isScansRendered if we're on /scans
      dispatch(setIsScansRendered(true))
    } else if (isUdesignMissing) {
      // Navigate to choosetype if udesign_json is missing
      navigate(`/patients/choosetype/patient/${patientId}/case/${caseId}`)
    } else if (
      !isScansRendered &&
      Object.keys(caseDetail).length &&
      caseDetail?.caseDisposition !== CASE_STATUS.FILL_OUT_RX_FORM &&
      caseDetail?.caseDisposition !== CASE_STATUS.SUBMIT_TO_UASSIST
    ) {
      navigate(`/records/patient/${patientId}/case/${caseId}/scans`)
    }
  }, [location.pathname, caseDetail])
  // dental next bool
  const [dentalGoNext, setDentalGoNext] = useState<boolean | undefined>(
    undefined,
  )
  const handleSelfplanNext = () => {
    if(activeStep === 1){
      navigate(`/clinical/patient/${patientId}/case/${caseId}/treat`)
    }
  }
  // next click
  const newNextClick = () => {
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))

    if (activeStep === 0) {
      dispatch(setClinicalData({ isLoading: "pending" }))
      setFlagNextBtn(!flagNextBtn)
    } else if (isStepOptional(activeStep)) {
      handleSkip()
    } else {
      if (caseRAtype === "selfplan"){
        handleSelfplanNext()
      }
      else {
        handleNext()
      }
    }
  }
  // photo 是否可以下一步判断
  useEffect(() => {
    const { normalPosition, xrayPosition } =
      checkIsThereASpecifiedPhoto(photoTypeJson)
    if (normalPosition && xrayPosition) {
      setPhotographErrors(false)
    } else {
      setPhotographErrors(true)
    }
    setShowError(false)
  }, [photoTypeJson])
  // scan 没上传不能下一步
  useEffect(() => {
    if (scanErrors && activeStep === 0) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [scanErrors])
  return (
    <>
      <PatientInfoHeader
        chipLabel={t("records.description")}
        onBtnCancelClick={() => {
          navigate("/patients")
        }}
        cancelBtnText={t("button.close")}
        chipSx={{
          background: "#0288D1",
          fontSize: "13px",
          fontWeight: "400px",
          color: "primary.contrastText",
        }}
      />
      <Container component="main" maxWidth={"lg"} sx={{ mb: 4 }}>
        <UText
          sxProp={{ display: "flex", justifyContent: "center" }}
          variant={"h4"}
        >
          {t("records.title")}
        </UText>
        {/* {caseRAtype !== "selfplan" && */}
          <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: 2, pb: 3 }}>
            {recordsStepper.map((s, index) => {
              const stepProps: { completed?: boolean } = {}
              const labelProps: {
                optional?: React.ReactNode
                error?: boolean
              } = {}
              if (showError && isStepFailed(index) && activeStep === index) {
                labelProps.error = true
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false
              }

              return (
                <Step key={s.label} {...stepProps}>
                  <StepLabel
                    icon={
                      labelProps.error && (
                        <ErrorOutlinedIcon sx={{ color: "error.main" }} />
                      )
                    }
                    {...labelProps}
                  >
                    {s.label}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>  
      {/* } */}
        
        <Paper
          elevation={0}
          sx={{
            my: { xs: 3, md: 2 },
            p: "40px",
            borderRadius: "16px",
            marginTop: "0",
            margin: "0 auto",
            width: "max-content",
          }}
        >
          <RecordRoutes
            xrayErrors={xrayErrors}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            showError={showError}
            setScanErrors={setScanErrors}
            setPhotographErrors={setPhotographErrors}
            setXrayErrors={setXrayErrors}
            flagNextBtn={flagNextBtn}
            steps={recordsStepper}
            setIsDragEmpty={setIsDragEmpty}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            isDragOpen={isDragOpen}
            setIsDragOpen={setIsDragOpen}
            handleSkip={handleSkip}
            dentalGoNext={dentalGoNext}
            setDentalGoNext={setDentalGoNext}
            needDoPresetup={needDoPresetup}
            setNeedDoPresetup={setNeedDoPresetup}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 1,
            }}
          >
            {/* {caseRAtype !== "selfplan" && */}
              <Button
                variant="outlined"
                onClick={handleBack}
                sx={{
                  width: "96px",
                  height: "36px",
                  display:
                    (caseRAtype === "retainer" &&
                      (activeStep === 0 || activeStep === 2)) ||
                    (caseRAtype === "alinger" &&
                      (activeStep === 0 || activeStep === 3)) ||
                    (caseRAtype === "selfplan" && activeStep === 0)
                      ? "none"
                      : "inline-flex",
                }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                {t("button.back")}
              </Button>
            {/* } */}
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                newNextClick()
                // dispatch(
                //   setIsRouteChangeBlocked({ isRouteChangeBlocked: false }),
                // )
                // if (activeStep === 0) {
                //   dispatch(setClinicalData({ isLoading: "pending" }))
                //   setFlagNextBtn(!flagNextBtn)
                // } else if (isStepOptional(activeStep)) {
                //   handleSkip()
                // } else {
                //   handleNext()
                // }
              }}
              variant="contained"
              sx={{
                width: "92px",
                height: "36px",
                display:
                  (caseRAtype === "alinger" && activeStep === 3) ||
                  (caseRAtype === "retainer" && activeStep === 2)
                    ? "none"
                    : "inline-flex",
              }}
              disabled={
                isPhotoUploading === "pending" ||
                xrayLoading === "pending" ||
                xrayUploadLoading === "pending" ||
                isDownloading === "pending" ||
                individualPhotosCount !== count
              }
            >
              {t("button.next")}
              <ChevronRightIcon sx={{ marginLeft: "3px" }} fontSize={"small"} />
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default RecordModule
