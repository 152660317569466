import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material"
import ConstructionIcon from "@mui/icons-material/Construction"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import {
  setAutoProActiveStep,
  setGoAutoProcesses,
} from "@/UDTreat/udTreatSlice"
import { viewControlInTreatment } from "@/gluelayer"
const AutoBottom: FC = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { autoProActiveStep } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const handleNext = () => {
    if (autoProActiveStep < 4) {
      dispatch(setAutoProActiveStep(autoProActiveStep + 1))
    }
    if (autoProActiveStep === 4) {
      // finish
      console.log("finish")
      dispatch(setGoAutoProcesses(false))
    }
  }
  const handleBack = () => {
    if (autoProActiveStep > 0) {
      dispatch(setAutoProActiveStep(autoProActiveStep - 1))
    }
  }

  useEffect(() => {
    console.log("autoProActiveStep", autoProActiveStep)
    viewControlInTreatment.setSpacecheckVisibility(false)
    switch (autoProActiveStep) {
      case 0:
        break
      case 1:
        break
      case 2:
        break
      case 3:
        viewControlInTreatment.setSpacecheckVisibility(true)
        break
      case 4:
        break
      default:
    }
  }, [autoProActiveStep])
  return (
    <Box
      sx={{
        width: "100%",
        height: "40px",
        position: "absolute",
        bottom: "20px",
        // background: "red",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        disabled={autoProActiveStep === 0}
        onClick={handleBack}
        sx={{ width: "100px", marginRight: "200px" }}
        variant="contained"
      >
        {"Back"}
      </Button>
      <Button onClick={handleNext} sx={{ width: "100px" }} variant="contained">
        {autoProActiveStep === 4 ? "Finish" : "Next"}
      </Button>
      <Box
        sx={{
          position: "absolute",
          left: "calc(50% + 300px)",
          top: "-10px",
          display: autoProActiveStep === 4 ? "block" : "none",
        }}
      >
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Box>
              <Typography
                variant="body1"
                component="span"
                display="inline"
                style={{ color: "#fff" }}
              >
                Don't run checks automatically next time. I will check and fix
                individually from the tools set
                <ConstructionIcon
                  sx={{ marginLeft: "12px" }}
                  color={"primary"}
                ></ConstructionIcon>
              </Typography>
            </Box>
          }
        />
      </Box>
    </Box>
  )
}
export default AutoBottom
