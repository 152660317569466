import { EArchType, NodeStru } from "../../common";
import { globalEvents, GlobalEvents } from "../../../utils/globalevents";
import { wasmModule } from "../wasm/wasmModule";
import { treatmentPlan } from "../treatmentplan";
import { EBiteJumpLocationType } from "../../typesofinterface";
import { refinementModule } from "../refinement";
import { ESetupType } from "../../common/commontypes";

/**
 * stagebar 数据更新通知数据
 */
export interface JointStageData {
  jointUpKeypoints: NodeStru[]; // 拼接上颌stage数据
  jointLowerKeypoints: NodeStru[]; // 拼接下颌stage数据

  curUpNodeIndex: number; // 当前上颌stage number
  curLowerNodeIndex: number; // 当前下颌stage number
  keypointsUpMaxStep: number; //上颌最大stage step
  keypointsDownMaxStep: number; //下颌最大stage step
  hasUpLastData: boolean; // 该上颌拼接数据是否存在正常模式下的数据
  hasLowerLastData: boolean; // 该下颌拼接数据是否存在正常模式下的数据
  refinementHistoryUpNumber: number; //上颌历史refinement个数
  historyStepUpCount: number; //上颌存在refinement的情况下，历史的step个数，比如：R2，7，8，9...， 历史个数则为7
  refinementHistoryDownNumber: number; //下颌历史refinement个数
  historyStepDownCount: number; //下颌存在refinement的情况下，历史的step个数，比如：R1，3，4，5...， 历史个数则为3
  upStartStep: number; // 上颌的起始step
  lowStartStep: number; // 下颌的起始step
}

// import { disPatchPanelDataCallback } from "../toothmanager/toothmovement";

class StagingManager {
  /**
   * current step number of upper
   */
  stageupIndex = 0;
  /**
   * current step number of lower
   */
  stagelowIndex = 0;
  /**
   * max step number of upper
   */
  stageUpnNumber = 0;
  /**
   * max step number of lower
   */
  stageLowNumber = 0;
  showRefinementHistoryRangeType = ""; //for ui controll

  /**
   * use to store temp index for IC
   */
  lastNoZeroUpIndex = 0;
  lastNoZeroLowerIndex = 0;
  wasmStageData: JointStageData = {
    jointLowerKeypoints: [],
    jointUpKeypoints: [],
    hasLowerLastData: true,
    hasUpLastData: true,
    keypointsUpMaxStep: 0,
    keypointsDownMaxStep: 0,
    curLowerNodeIndex: 0,
    curUpNodeIndex: 0,
    refinementHistoryUpNumber: 0,
    historyStepUpCount: 0,
    refinementHistoryDownNumber: 0,
    historyStepDownCount: 0,
    upStartStep: 0,
    lowStartStep: 0,
  };

  getCurrentStageIndex(): { upper: number; lower: number } {
    return {
      upper: this.wasmStageData.curUpNodeIndex,
      lower: this.wasmStageData.curLowerNodeIndex,
    };
  }

  clearWasmStageData = () => {
    this.wasmStageData.jointLowerKeypoints = []
    this.wasmStageData.jointUpKeypoints = []
    this.wasmStageData.hasLowerLastData = true
    this.wasmStageData.hasUpLastData = true
    this.wasmStageData.keypointsUpMaxStep = 0
    this.wasmStageData.keypointsDownMaxStep = 0
    this.wasmStageData.curLowerNodeIndex = 0
    this.wasmStageData.curUpNodeIndex = 0
    this.wasmStageData.refinementHistoryUpNumber = 0
    this.wasmStageData.historyStepUpCount = 0
    this.wasmStageData.refinementHistoryDownNumber = 0
    this.wasmStageData.historyStepDownCount = 0
    this.wasmStageData.upStartStep = 0
    this.wasmStageData.lowStartStep = 0
  }

  getWasmStageData = () => {
    return this.wasmStageData
  }

  setStageCallback?: (stageData: any) => void;
  setGMProgressCallBack?: (percent:number) => void;
  setGM0ProgressCallBack?: (viewID:number, percent:number) => void;
  setGM1ProgressCallBack?: (viewID:number, percent:number) => void;
  /**
   * callChangeTx: 1:call chanage tx api, 0: do not call
   * changeTxToIndex: change the ui index
   */
  setTxPlanChangedCallBack?: (callChangeTx:number, changeTxToIndex:number) => void;
  setTxPlanDataChangedCallBack?: () => void;
  setupTypeAndStage?: (type: ESetupType, ndoeIndex: number) => void;
  GetNodeTypes = nodeType => {
    const eNodeTypes: number[] = [];
    for (let index = 0; index < 16; index++) {
      const val = 1 << index;
      if (nodeType & val) {
        eNodeTypes.push(index);
      }
    }
    return eNodeTypes;
  };
  isStageIndexToothMoveTypes = (arch: number) => {
    let nodeType: number[] = [];
    if (arch === 0 && this.wasmStageData.jointUpKeypoints.length > 0)
      nodeType =
        this.wasmStageData.jointUpKeypoints[this.wasmStageData.curUpNodeIndex]
          .nodeType;
    else if (arch === 1 && this.wasmStageData.jointLowerKeypoints.length > 0)
      nodeType =
        this.wasmStageData.jointLowerKeypoints[
          this.wasmStageData.curLowerNodeIndex
        ].nodeType;
    for (let i = 0; i < nodeType.length; i++) {
      if (nodeType[i] === 0) return true;
    }
    return false;
  };

  isOnThefinalStep(archType: EArchType) {
    if (archType === EArchType.UpArch) {
      return this.stageupIndex === this.stageUpnNumber;
    } else if (archType === EArchType.LowArch) {
      return this.stagelowIndex === this.stageLowNumber;
    }
  }

  updateStagesCallback(callback: (arch: number, index: number) => void) {
    (window as any).updateStagesCallback = callback;
    wasmModule.module
      .getScanPreviewWin()
      .SetUpdateSliderCB("updateStagesCallback");
  }

  getNodeTypesByStep = (nodes, stepIndex) => {
    let nodeTypes = -1;
    for (let i = 0; i < nodes.size(); i++) {
      const node = nodes.get(i);
      if (node.stepindex === stepIndex) {
        nodeTypes = node.GetNodeType();
      }
    }
    return nodeTypes;
  };

  /**
   * 刷新StageData
   * @param arch
   * @param nodes
   */
  setStageData = async (arch, nodes) => {
    const nodesSize = nodes.size();
    let lastStepNumber = 0;
    if (nodesSize > 0) {
      const lastNode = nodes.get(nodesSize - 1);
      lastStepNumber = lastNode.stepindex;
      // console.info("=====lastStepNumber:" + lastStepNumber)
    }
    if (arch === 0) {
      this.stageUpnNumber = lastStepNumber;
      this.wasmStageData.jointUpKeypoints = [];
      //  this.wasmStageData.curUpNodeIndex = this.stageUpnNumber;
    } else if (arch === 1) {
      this.stageLowNumber = lastStepNumber;
      this.wasmStageData.jointLowerKeypoints = [];
      // this.wasmStageData.curLowerNodeIndex = this.stageLowNumber;
    }

    const refinementHistoryNumber =
      refinementModule.getNumberOfRefinement(arch);
    let historyStepNumber = 0;
    if (refinementHistoryNumber > 0) {
      historyStepNumber = refinementModule.getHistoryStageNumber(arch);
    }
    const stageNameArray = refinementModule.getRefinementInfo(arch);
    if (arch === 0) {
      // console.info("===== up arch stageNameArray: ", stageNameArray);
      if (this.showRefinementHistoryRangeType !== "") {
        this.stageUpnNumber = stageNameArray.length - 1;
      }
      this.wasmStageData.refinementHistoryUpNumber = refinementHistoryNumber;
      this.wasmStageData.historyStepUpCount = historyStepNumber;
    } else if (arch === 1) {
      // console.info("===== down arch stageNameArray: ", stageNameArray);
      if (this.showRefinementHistoryRangeType !== "") {
        this.stageLowNumber = stageNameArray.length - 1;
      }
      this.wasmStageData.refinementHistoryDownNumber = refinementHistoryNumber;
      this.wasmStageData.historyStepDownCount = historyStepNumber;
    }

    this.wasmStageData.upStartStep = refinementModule.getStageStartNumber(0)
    this.wasmStageData.lowStartStep = refinementModule.getStageStartNumber(1)

    for (let i = 0; i <= lastStepNumber && lastStepNumber > 0; i++) {
      const nodeStru: NodeStru = {
        nodeType: [],
        stepindex: -1,
        posPace: 0.25,
        degreePace: 2.5,
        name: "",
      };
      nodeStru.stepindex = i;

      //show nornal
      if (this.showRefinementHistoryRangeType === "") {
        if (historyStepNumber === 0) {
          nodeStru.name = i + "";
        } else {
          if (i === 0) {
            nodeStru.name = "R" + refinementHistoryNumber;
          } else {
            nodeStru.name = i + historyStepNumber - 1 + "";
          }
          if(i < stageNameArray.length) {
            nodeStru.name = stageNameArray[i]
            // if (nodeStru.name.includes("R")) {
            //   nodeStru.name = nodeStru.name.replace("R", "C");
            // }
          }
        }
      } else {
        //show history
        nodeStru.name = stageNameArray[i];
        // if (nodeStru.name.includes("R")) {
        //   nodeStru.name = nodeStru.name.replace("R", "C");
        // }
      }

      for (let j = 0; j < nodes.size(); j++) {
        const node = nodes.get(j);
        if (node.stepindex === i) {
          // console.log('setnode index::',i)
          nodeStru.degreePace = node.degreePace;
          nodeStru.posPace = node.posPace;
          nodeStru.nodeType = this.GetNodeTypes(node.GetNodeType());
        }
      }
      if (arch === 0) this.wasmStageData.jointUpKeypoints.push(nodeStru);
      else if (arch === 1)
        this.wasmStageData.jointLowerKeypoints.push(nodeStru);
    }
  };
  patientstxNumber = () => {
    let txNumber = 0;
    if (
      this.wasmStageData.jointUpKeypoints.length !== 0 ||
      this.wasmStageData.jointLowerKeypoints.length !== 0
    ) {
      txNumber++;
    }
    if (wasmModule.stagingcontoler.Tx2Exist()) {
      txNumber++;
    }
    return txNumber;
  };

  /**
   * 更新stageData Data，计算一些中间值，并通知界面重绘
   */
  private _updateStageDataToUI() {
    this.wasmStageData.keypointsUpMaxStep =
      this.wasmStageData.jointUpKeypoints.length - 1;
    this.wasmStageData.keypointsDownMaxStep =
      this.wasmStageData.jointLowerKeypoints.length - 1;
    this.stageupIndex = this.wasmStageData.curUpNodeIndex;
    this.stagelowIndex = this.wasmStageData.curLowerNodeIndex;

    const stageData: JointStageData = JSON.parse(
      JSON.stringify(this.wasmStageData)
    );
    if (this.setStageCallback) this.setStageCallback(stageData);
  }

  /**
   * 设置stage当前Index,并更新stageData
   * @param arch
   * @param index
   */
  private _updateCurIndex = async (arch, index) => {
    if (arch === 0) this.wasmStageData.curUpNodeIndex = index;
    else if (arch === 1) this.wasmStageData.curLowerNodeIndex = index;

    if (arch === 0) {
      if (index !== 0) {
        this.lastNoZeroUpIndex = index;
      }
    } else if (arch === 1) {
      if (index !== 0) {
        this.lastNoZeroLowerIndex = index;
      }
    }

    this._updateStageDataToUI();
  };

  deleteKeyNode(archType: boolean, stepIndex: number) {
    let type = 1;
    if (archType) type = 0;
    wasmModule.stagingcontoler.DeleteNode(type, stepIndex);
  }
  addNormalNode(archType: boolean, stepIndex: number, lastNode: boolean) {
    let type = 1;
    if (archType) type = 0;
    wasmModule.stagingcontoler.AddNormalNode(type, stepIndex, lastNode);
  }

  addPassiveNode(archType: boolean, stageIndex: number) {
    let type = 1;
    if (archType) type = 0;
    wasmModule.stagingcontoler.addPassiveNode(type, stageIndex)
  }

  deletePassiveNode(archType: boolean, stageIndex: number) {
    let type = 1;
    if (archType) type = 0;
    wasmModule.stagingcontoler.deletePassiveNode(type, stageIndex)
  }

  addPassiveAligner() {
    wasmModule.stagingcontoler.AddPassiveAligner()
  }

  delPassiveAligner() {
    wasmModule.stagingcontoler.DelPassiveAligner()
  }

  VelocityUpAndDownArchEqual() {
    if (!wasmModule.isInit) return;

    wasmModule.stagingcontoler.VelocityUpAndDownArchEqual();

    this.updateStagesFromWasm();
  }
  VelocityUpArchPlus() {
    if (!wasmModule.isInit) return;
    wasmModule.stagingcontoler.VelocityUpArchPlus();
    this.updateStagesFromWasm();
  }
  VelocityUpArchMinus() {
    if (!wasmModule.isInit) return;

    wasmModule.stagingcontoler.VelocityUpArchMinus();

    this.updateStagesFromWasm();
  }
  VelocityUpArchRestore() {
    if (!wasmModule.isInit) return;

    wasmModule.stagingcontoler.VelocityUpArchRestore();

    this.updateStagesFromWasm();
  }
  VelocityDownArchPlus() {
    if (!wasmModule.isInit) return;

    wasmModule.stagingcontoler.VelocityDownArchPlus();

    this.updateStagesFromWasm();
  }
  VelocityDownArchMinus() {
    if (!wasmModule.isInit) return;

    wasmModule.stagingcontoler.VelocityDownArchMinus();

    this.updateStagesFromWasm();
  }
  VelocityDownArchRestore() {
    if (!wasmModule.isInit) return;

    wasmModule.stagingcontoler.VelocityDownArchRestore();

    this.updateStagesFromWasm();
  }
  /**
   * 在setup完成后通知stagebar刷新的回调
   * stagemanager中有两个重要回调
   * initFinishedupdateStageCallback 在setup完成后通知需要更新stage的信息
   * updateStageCallback 在移牙或者stage方案变化后会通知更新stage信息
   *
   */
  initFinishedupdateStageCallback() {
    //open refinment module
    refinementModule.openRefinementModule(true);

    this.stageupIndex = 0;
    this.stagelowIndex = 0;
    this.stageUpnNumber = 0;
    this.stageLowNumber = 0;
    // 从wasm更新stage信息
    this.updateStagesFromWasm();
    // 设置当前index到最后一步并更新StageData
    this.wasmStageData.curUpNodeIndex =
      this.wasmStageData.jointUpKeypoints.length - 1;
    this.wasmStageData.curLowerNodeIndex =
      this.wasmStageData.jointLowerKeypoints.length - 1;
    this._updateStageDataToUI();
  }
  /**
   * invote from wasm after stage updating.
   * @param arch
   * @param index
   */
  updateStageCallback(arch: number, index: number) {
    // console.log('>>>>>updateStageCallback',arch,index)
    this.updateStagesFromWasm();
    this._updateCurIndex(arch, index);
  }
  /**
   * update stage infos from WASM
   * 调用此方法会去WASM中获取stage节点信息并放入wasmStageData信息中
   */
  updateStagesFromWasm() {
    treatmentPlan.checkTx()
    const curTxNumber = treatmentPlan.currentTxIndex;
    // console.info("=====currentTxIndex:" + curTxNumber);
    // const upArchRefinementHistoryNumber =
    //   refinementModule.getNumberOfRefinement(0);
    // const downArchRefinementHistoryNumber =
    //   refinementModule.getNumberOfRefinement(1);
    // console.info(
    //   "=====upArchRefinementHistoryNumber:",
    //   upArchRefinementHistoryNumber
    // );
    // console.info(
    //   "=====downArchRefinementHistoryNumber:",
    //   downArchRefinementHistoryNumber
    // );

    let upNodes, lowerNodes;
    //do not show history
    if (this.showRefinementHistoryRangeType === "") {
      upNodes = wasmModule.stagingcontoler.GetNodes(0, curTxNumber);
      lowerNodes = wasmModule.stagingcontoler.GetNodes(1, curTxNumber);
    } else {
      //show history
      upNodes = refinementModule.getCurNodes(0);
      lowerNodes = refinementModule.getCurNodes(1);
    }

    if (upNodes) {
      for (let j = 0; j < upNodes.size(); j++) {
        const node = upNodes.get(j);
        console.info(
          "=====upNodes:" + node.stepindex + "," + node.GetNodeType()
        );
      }
      this.setStageData(0, upNodes);
    } else {
      this.wasmStageData.jointUpKeypoints = []
      this.wasmStageData.hasUpLastData = true
      this.wasmStageData.keypointsUpMaxStep = 0
      this.wasmStageData.curUpNodeIndex = 0
      this.wasmStageData.refinementHistoryUpNumber = 0
      this.wasmStageData.historyStepUpCount = 0
      this.wasmStageData.upStartStep = 0
    }

    if (lowerNodes) {
      for (let j = 0; j < lowerNodes.size(); j++) {
        const node = lowerNodes.get(j);
        console.info(
          "=====lowerNodes:" + node.stepindex + "," + node.GetNodeType()
        );
      }
      this.setStageData(1, lowerNodes);
    } else {
      this.wasmStageData.hasLowerLastData = true
      this.wasmStageData.jointLowerKeypoints = []
      this.wasmStageData.keypointsDownMaxStep = 0
      this.wasmStageData.historyStepDownCount = 0
      this.wasmStageData.refinementHistoryDownNumber = 0
      this.wasmStageData.lowStartStep = 0
      this.wasmStageData.curLowerNodeIndex = 0
    }

    upNodes = null;
    lowerNodes = null;
  }
  /**
   * 设置wasm-stage当前index
   */
  setStageStep = (up, down) => {
    // console.log("set stage 1111", up, down);
    if (up != undefined) this.wasmStageData.curUpNodeIndex = up;
    if (down != undefined) this.wasmStageData.curLowerNodeIndex = down;
    if (up && up !== 0) {
      this.lastNoZeroUpIndex = up;
    }
    if (down && down !== 0) {
      this.lastNoZeroLowerIndex = down;
    }

    if (this.showRefinementHistoryRangeType === "") {
      if (this.stageupIndex !== up || this.stagelowIndex !== down)
        this._setStagePromise(up, down);
    } else {
      if (this.stageupIndex !== up) {
        console.info("=====changeDisplayStageValue: ", up, "arch type: ", 0);
        refinementModule.changeDisplayStageValue(up, 0);
        this.stageupIndex = up;
      }
      if (this.stagelowIndex !== down) {
        console.info("=====changeDisplayStageValue: ", down, "arch type: ", 1);
        refinementModule.changeDisplayStageValue(down, 1);
        this.stagelowIndex = down;
      }
    }

    this._updateStageDataToUI();
    // disPatchPanelDataCallback();
  };

  private _setStagePromise = (upperStageIndex, lowerStageIndex) => {
    this.stageupIndex = upperStageIndex;
    this.stagelowIndex = lowerStageIndex;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let stageup = this.stageupIndex;
        let stagelow = this.stagelowIndex;
        if (stageup === null) stageup = 0;
        if (stagelow === null) stagelow = 0;
        if (stageup > this.stageUpnNumber) {
          stageup = this.stageUpnNumber;
          this.stageupIndex = this.stageUpnNumber;
        }
        if (stagelow > this.stageLowNumber) {
          stagelow = this.stageLowNumber;
          this.stagelowIndex = this.stageLowNumber;
        }
        if (wasmModule.isInit) this._changeWasmStage(stageup, stagelow);
      }, 20);
    });
  };
  /**
   * 设置wasm-stage当前index
   * @param stageupIndex
   * @param stagelowIndex
   */
  private _changeWasmStage(stageupIndex, stagelowIndex) {
    if (wasmModule.stagingcontoler && wasmModule.stagingcontoler.ChangeStage) {
      wasmModule.stagingcontoler.ChangeStage(stageupIndex, 0);
      wasmModule.stagingcontoler.ChangeStage(stagelowIndex, 1);
      globalEvents.fire(GlobalEvents.ON_STAGESTEP_CHANGED, {
        upStep: stageupIndex,
        downStep: stagelowIndex,
      });
    }
  }
  setEndNodeIndex() {
    const upIndex = this.wasmStageData.jointUpKeypoints.length - 1;
    const downIndex = this.wasmStageData.jointLowerKeypoints.length - 1;
    console.log("upIndex-1>>", upIndex);
    console.log("downIndex-1>>", downIndex);
    if (upIndex >= downIndex) this._updateCurIndex(0, upIndex);
    else this._updateCurIndex(1, downIndex);
    this._changeWasmStage(upIndex, downIndex); 
    // this.setStageStep(upIndex, downIndex);
  }
  VelocityModifyPaceValue(
    archType: number,
    movePace: number,
    rotatePace: number,
    startNum: number,
    endNum: number
  ) {
    if (!wasmModule.isInit) return;

    wasmModule.stagingcontoler.VelocityModifyPaceValue(
      archType,
      movePace,
      rotatePace,
      startNum,
      endNum
    );

    this.updateStagesFromWasm();
  }

  GetDefaultSpaceAndRotateValue(archType: number): number[] {
    if (!wasmModule.isInit) return [0.5, 0.5];
    const res =
      wasmModule.stagingcontoler.GetDefaultSpaceAndRotateValue(archType);
    return [res[1], res[1]];
  }

  /**
   * 设置bite jump的类型
   * @param biteJumpType
   */
  SetBiteJumpType(biteJumpType: EBiteJumpLocationType) {
    wasmModule.stagingcontoler?.SetBiteJumpType(biteJumpType);
  }

  /**
   * set the gm to final
   */
  SetGMToFinal() {
    wasmModule.statusController?.SetGMToFinal()
  }
}
export const stagingManager = new StagingManager();

globalEvents.on(GlobalEvents.WASM_CANVAS_CHANGED, () => {
  console.log("WASM_CANVAS_CHANGED");
  const stageData: JointStageData = JSON.parse(
    JSON.stringify(stagingManager.wasmStageData)
  );
  if (stagingManager.setStageCallback)
    stagingManager.setStageCallback(stageData);
});
