import { FC, forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { useParams } from "react-router-dom"
import { Box, Button } from "@mui/material"
import { pdf, PDFViewer } from "@react-pdf/renderer"
import html2canvas from "html2canvas"

import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { uploadReportpdf } from "../../../../core/app/slices/records/xrays"
import { fetchUserProfile } from "../../../../core/app/slices/user/userApis"
import { RootState } from "../../../../core/app/store"
import AttachmentComponent from "../Report/components/Attachment/attachment"
import Auxilliaries from "../Report/components/Auxilliaries/auxilliaries"
import IPR from "../Report/components/IPR/ipr"
import { reportPDFProps, reportProps } from "../Report/type"

import ReportPDF from "./reportPdf"

import "../Report/report.scss"
import {
  setIsShowProgress,
  setProgressMsg,
} from "@/core/app/slices/clinical/clinicalSlice"
import { dateFormat } from "@/core/utils/formatters"
import { stagingManager, wasmModule } from "@/gluelayer"
import { refinementModule } from "@/gluelayer/core/modules/refinement"

export const ScreeshotReport = forwardRef(
  ({ isReady, toothlist, attachment, iprData }: reportPDFProps, ref) => {
    const { patientData } = useAppSelector(
      (state: RootState) => state.patientService,
    )
    const { caseId } = useParams()

    const { org_name, createDate } = useAppSelector(
      (state: RootState) => state.caseService.caseDetail,
    )
    const dispatch = useAppDispatch()

    const attRef = useRef(null)
    const auxRef = useRef(null)
    const iprRef = useRef(null)

    useEffect(() => {
      dispatch(fetchUserProfile())
    }, [])

    useImperativeHandle(ref, () => ({
      uploadReportPDF: (status: "open" | "upload") => {
        exportComponentAsPdf(status)
      },
    }))

    const convertLocalTime = (utcTimeStr) => {
      const utcDate = new Date(utcTimeStr)
      const options = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      const localTimeStr = utcDate.toLocaleString("en-US", options)
      console.log("🚀 ~ convertLocalTime ~ localTimeStr:", localTimeStr)
      return localTimeStr
    }

    const exportComponentAsPdf = (status: "open" | "upload") => {
      if (attRef && auxRef && iprRef) {
        Promise.all([
          html2canvas(attRef.current, { scale: 2 }),
          html2canvas(auxRef.current, { scale: 2 }),
          html2canvas(iprRef.current, { scale: 2 }),
        ])
          .then(async (res) => {
            const attCanvas = res[0]
            const auxCanvas = res[1]
            const iprCanvas = res[2]

            const auximgDataUrl = auxCanvas.toDataURL("image/png")
            const iprimgDataUrl = iprCanvas.toDataURL("image/png")
            const attimgDataUrl = attCanvas.toDataURL("image/png")

            let refinementLastDate = ""
            const refinementHistoryList =
              refinementModule.getDetailedHistoryDateInfo()

            const firstKey = Object.keys(refinementHistoryList)[0]
            if (refinementHistoryList[firstKey] !== "") {
              refinementLastDate = refinementHistoryList[firstKey]
              console.log("🚀 ~ .then ~ lastDate:", refinementLastDate)
            }

            const blob = await pdf(
              ReportPDF({
                auxshot: auximgDataUrl,
                iprshot: iprimgDataUrl,
                attshot: attimgDataUrl,
                patientName: patientData.firstName + " " + patientData.lastName,
                caseID: caseId,
                org_name,
                createDate:
                  refinementLastDate != ""
                    ? refinementLastDate
                    : dateFormat(createDate, "YYYY-MM-DD HH:mm:ss"),
                stages: [
                  stagingManager.wasmStageData.keypointsUpMaxStep,
                  stagingManager.wasmStageData.keypointsDownMaxStep,
                ],
              }),
            ).toBlob()

            if (status == "upload") {
              const newFile = new File([blob], `ipr-att-report.pdf`, {
                type: "image/png",
              })
              const formData = new FormData()
              formData.append(
                "attachment",
                new File([blob], newFile.name, {
                  type: "image/png",
                }),
              )
              formData.append("original_file_name", "ipr-att-report.pdf")

              dispatch(
                uploadReportpdf({
                  file: blob,
                  patientId: patientData.id,
                  caseId,
                  formData: formData,
                  fileName: newFile.name,
                }),
              )
            } else {
              const url = URL.createObjectURL(blob)
              window.open(url, "_blank")
            }
          })
          .finally(() => {
            dispatch(setIsShowProgress(false))
          })
      }
    }
    return (
      isReady && (
        <Box
          sx={{
            position: "fixed",
            zIndex: -100, //Just need to get the dom element, don't need to display it
          }}
        >
          <Box
            width={710}
            height={1080}
            sx={{
              backgroundColor: "#ECEFF1",
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
              id="attachment"
              ref={attRef}
            >
              <AttachmentComponent
                toothlist={toothlist}
                attachment={attachment}
              />
            </div>

            <div
              style={{
                position: "relative",
              }}
              id="auxilliaries"
              ref={auxRef}
            >
              <Auxilliaries toothlist={toothlist} attachment={attachment} />
            </div>

            <div
              style={{
                position: "relative",
                width: 710,
                height: 703,
                // backgroundColor: "red",
              }}
              id="ipr"
              ref={iprRef}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: 663,
                  borderRadius: 4,
                  // padding: "0 16px",
                  // backgroundColor: "lightgreen",
                }}
              >
                <IPR toothlist={toothlist} iprData={iprData} isPDF={true} />
              </div>
            </div>
          </Box>
        </Box>
      )
    )
  },
)
ScreeshotReport.displayName = "ScreeshotReport"
