import { type } from "os"
import { ArchformType, EToothColorType } from "../modules"
import { EBiteJumpLocationType } from "../typesofinterface"

export type CaseZipType =
  | "Raw"
  | "Photo"
  | "Setting Data0"
  | "Setting Data1"
  | "Setting Data2"
  | "Setting Data10"

export type ZipFileName =
  | "bulk0"
  | `bulk1`
  | `bulk2`
  | `bulk10`
  | `raw`
  | `photo`

export type ZipFileList =
  | [ZipFileName]
  | [ZipFileName, ZipFileName]
  | [ZipFileName, ZipFileName, ZipFileName]
  | [ZipFileName, ZipFileName, ZipFileName, ZipFileName]
  | [ZipFileName, ZipFileName, ZipFileName, ZipFileName, ZipFileName]
  | [
      ZipFileName,
      ZipFileName,
      ZipFileName,
      ZipFileName,
      ZipFileName,
      ZipFileName,
    ]

export enum EShowArchType {
  UpArch = 0,
  LowArch = 1,
  BothArch = 2,
}

export enum EArchType {
  UpArch = 0,
  LowArch = 1,
}

export interface CPlusArray<T> {
  size: () => number
  resize: (arg0, arg1) => void
  push_back: (arg0) => void
  get: (arg0) => T
  set: (arg0, arg1) => void
  ptrType: any
  ptr: number
}

// /**
//  * 牙齿 Property
//  */
// export enum ToothProperty {
//   ToothProperty_Normal = 0, // 正常
//   ToothProperty_PullOut = 1, // 拔除
//   ToothProperty_Implant = 2, // 种植牙（紫色）
//   ToothProperty_Missing = 3, // 缺失
//   ToothProperty_Fix = 4, // 固定不动（黑色）
//   ToothProperty_RetainerTooth = 5, // 保持器
// }

// /**
//  * 牙齿Attribute
//  */
// export interface Attribute {
//   isPullOut: boolean; // 拔牙
//   toothProperty: ToothProperty;
//   isCrown: boolean; // 牙冠
//   isBridge: boolean; // 牙桥
//   isImplant: boolean; // 种植牙

//   isUnerupted: boolean; // 临时牙
//   isTemporary: boolean; // 未张全牙

//   isPonticFlag: boolean[]; // pullout
//   isImplantFlag: boolean[]; // pullout
//   isPullOutFlag: boolean[]; // pullout
//   archType: number; // pullout
//   // pullOutFlagData: PullOutFlag;
// }

/**
 * stage node type
 */
export enum ENodeType {
  NodeNormal = 0,
  NodeIPR,
  NodeSmartStage,
  NodeDoctor,
  NodeRetouch,
  NodeFreeze,
  NodeQuick3,
  NodeEvelocityStart,
  NodeEvelocityEnd,

  // add xue
  NodeExpansion,
  NodeDistalization,
  NodeIPRCut,
  NodeFinal,
  NodeChain,
  NodePassiveAligner, // updated by xueyi
  NodeOverCorr, // updated by xueyi
}

/**
 * stage 节点数据
 */
export class NodeStru {
  nodeType: ENodeType[]
  stepindex: number
  posPace: number
  degreePace: number
  name: string
}

/**
 * The extra information of a case
 * follow to UD design
 */
export interface ICaseExtraAttr {
  /**
   * 是否植牙
   */
  Implant?: boolean
  /**
   * 是否缺牙
   */
  MissTooth?: boolean
  /**
   * 是否拔牙
   */
  Pullout?: boolean
  /**
   * 是否分牙完毕
   */
  SetUp?: boolean

  /**
   * // 是否做过dome		(Dome 目前Usmile无法添加Dome)
   */
  EruptionDome?: 0 | 1

  /**
   * 上下颌最大stagenum
   */
  UpperMaxStageNum?: number
  LowerMaxStageNum?: number
  /**
   * 上下颌有无retainer 0/1
   */
  UpperRetainer?: 0 | 1
  LowerRetainer?: 0 | 1

  /**
   *根据下单选择的stage中是否包含了attachment
   */
  UpperTemplate?: 0 | 1
  LowerTemplate?: 0 | 1
  /**
   * 做完course check，给用户三个选择 "0"：correct the course；(留旧方案) "1"：start a new course；（不留旧方案） "2"：stay the course （撤销这次cc,回到之前方案）
   */
  RetouchSolution?: "0" | "1" | "2"
  /**
   * (Aligner,Retainer,IDB,Ulite)
   */
  UpperType?: "A" | "R" | "I" | "U"
  LowerType?: "A" | "R" | "I" | "U"

  /**
   * 排牙前的时间单位(ms)
   */
  TimeBeforeSetUp?: number

  /**
   * 打开病例后从开始修改方案，到修改完成关病例的时间(ms)
   */
  TimeOnManualSetUp?: number

  /**
   * recessionfill 选择的牙齿ID列表 "[1,2,3]", // 牙号 1-32
   */
  recessionfill?: number[]

  /**
   * AP 附件 elasitic   （附件中的一类，在编辑AUX 和GM后需要保存）
   */
  ApAttachment?: boolean

  /**
   * 相邻牙齿间距大于5mm （??打开时候检测的最大牙齿间隙,没有接口）打开病例时更新
   */
  BigSpace?: boolean

  //-------------------------- FE provide ---------------------------------

  /**
   * 上颌打印/下单 最大stage number	（属于FE交互）
   */
  UpperPrintStageNum?: number
  LowerPrintStageNum?: number
  /**
   * 下单的时候有个 checkbox， 用户选中就是 true，反之false，checkbox 含义：Check the box if there are areas of bone loss
   */
  BoneLoss?: boolean
  /**
   * 下单时间
   */
  CurrentOrderDate?: string
  /**
   * 小日历时间
   */
  FutureOrderDate?: string
  /**
   * "2023.11.02 16:29:07.959" // 最后上传 case_extra_attrs 时间
   */
  saveTime?: string
}

/**
 * the case extra information follow to UD design
 */
export interface ICaseExtInfo {
  upper_stage: number
  lower_stage: number
  case_extra_attrs: ICaseExtraAttr
  complexity: number
  upper_retainer_stage: string
  lower_retianer_stege: string
}

export interface IWASMRootObject {
  _scanPreview: () => void
}

/**
 * WASM StatusController模块接口
 */
export interface IWASMStateController {
  DoneActionType:any
  /**
   * 设置切换canvas后的回调函数
   * @param callbackName
   * @returns
   */
  SetChangeRenderWindowCB: (callbackName: string) => void
  /**
   * 获取当前显示的ArchType
   * @returns
   */
  GetCurrentDiplayArch: () => EShowArchType
  /**
   * canvas中绘制IPR信息
   * @params  archType    当前的上下颚显示状态
   *          isEnabled   是否绘制
   *          stageIndexForShow  当前绘制的stage index number(因为IPR计算的始终是最后一步stage,显示数据所在的stage的位置和最终的stage位置可能不一致，所以需要指定显示的stage index number)
   * @returns void
   */
  DisplayArchIPR: (
    archType: EShowArchType,
    isEnabled: boolean,
    stageIndexForShow: number,
  ) => void

  /**
   * get ipr report
   * @param archType only EArchType.UpArch or EArchType.LowArch
   * @returns
   */
  GetIPRReport: (archType: EArchType) => any

  /**
   * 查询是否有附件
   * @returns
   */
  IsAddAttachment: () => boolean

  /**
   * 查询牙齿之间是否有Collision
   * @returns
   */
  HasCollision: () => boolean

  /**
   * 判断所有stage中是否存在IPR
   * @returns boolean
   */
  IsHasIPRNode: () => boolean

  /**
   * 显示附件
   * @param isEnabled
   * @returns
   */
  DisplayAttachment: (isEnabled: boolean) => void

  /**
   * 进入resegment功能
   * @returns boolean
   */
  TestResegmentOpenOrClose: (val: number) => boolean

  /**
   * 显示后视图
   * @returns
   */
  SetToBackView: () => void

  /**
   * 打开或者关闭 BiteCheck
   * @returns
   */
  TurnOnBiteCheck: () => void
  TurnOffBiteCheck: () => void

  /**
   * 左右视角控制
   * @returns
   */
  SetToRightBuccalView: () => void
  SetToAnteriorView: () => void
  SetToLeftBuccalView: () => void
  /**
   * 开核视角控制
   * @param isEnabled
   * @returns
   */
  SplitScreenDisplay: (isEnabled: boolean) => void
  /**
   * 显示上下颌控制
   * @returns
   */
  DisplayUpperArch: () => void
  DisplayLowerArch: () => void
  DisplayBothArch: () => void
  /**
   * 显示牙龈控制
   * @param isEnabled
   * @returns
   */
  SwitchGum: (isEnabled: boolean) => void

  /*
   * Show arch model (3D scan)
   */
  ShowArchScan: (show: boolean) => void

  /*
   * Update arch model (3D scan )
   */
  UpdateArchScan: () => void

  /* Save arch scan to stl */
  SaveScanStl: (fileFolder: string) => void

  /**
   * view <-> edit switch
   * @param isToEdit
   * @returns void
   */
  SwitchViewAndEdit: (isToEdit: boolean) => void

  GetStagesIPRReport: () => any

  /**
   * 给特殊牙齿设置颜色,颜色范围0-1
   * @returns
   */
  SetToothTypeColor: (toothType: any, r: number, g: number, b: number) => void

  /**
   * 是否开启wasm中默认设置特殊牙齿的颜色
   * @param enable
   * @returns
   */
  SetEnableToothColorSetting: (enable: boolean) => void

  /**
   * 是否显示牙龈
   * @param enable
   * @returns
   */
  ShowGum: (enable: boolean) => void

  /**
   * 获取上颌或下颌是否添加了biteramp
   * @param archType
   * @returns
   */
  IsHaveBiteRampOnArch: (archType: string) => boolean
  /**
   * 获取已拔掉的牙齿列表
   * @returns toothID数组
   */
  GetCurrentDisplayArchPulloutToothIDsNumberInfo: () => CPlusArray<number>

  /**
   * set the gm to final
   * @returns
   */
  SetGMToFinal: () => void

  /**
   * Getting whether the tooth is removable or not
   * @param toothid
   * @returns
   */
  IsToothCanMove: (toothid: number) => boolean

  /**
   * Check Collision With IPR
   * @param numberingSys
   * @returns
   */
  checkCollisionWithIPR: (numberingSys: string) => string
  /**
   * 设置排牙界面操作过的类型
   * @returns
   */
  SetDoneActionType: (actionType: string) => void
  /**
   * 获取在排牙界面操作过的类型
   * @returns
   */
  GetDoneActionType: () => string
}

export interface IAttachmentSelectAbleType {
  NormalAttachment: string
  ButtonAttachment: string
  AttachmentAndTeeth: string
}

export interface IVertionType {
  E_Copa: string
  E_CopaSpecial: string
  E_UWeb: string
  E_UDesign: string
  E_USmileAI: string
  E_Ucloud20: string
}

export interface IWASMArchType {
  DownArch: string
  UpArch: string
  BothArch: string
}

/**
 *  WASM interface of preview
 *
 * enable preview
 * this will invoke a loop can't do something after invoke this function
 */
// wasmModule.module._scanPreview();

/**
 * get scanpreview object,need invoke after enable scanPreview
 *
 */
// wasmModule.module.getScanPreviewWin():IScanPreviewObject
export interface IPreviewModule {
  /**
   * draw arch
   */
  UpdateArch: (upStlPath: string, lowerStlPath: string) => void
  /**
   * set range of zoom scale
   * @param val
   * @returns
   */
  SetMinScaleValue: (val: number) => void
  SetMaxScaleValue: (val: number) => void
  /**
   * remove arch
   * @param isupper
   * @param islower
   * @returns
   */
  RemoveArch: (upper: boolean, lower: boolean) => boolean
  /**
   * change showing arch upper or lower
   * @param archmode
   * @returns
   */
  OnArchChanged: (showArchType: EShowArchType) => boolean
  /**
   * set zoom value
   * @param scale
   * @returns
   */
  ZoomBySlider: (val: number) => void
  /**
   * set zoom callback
   * @param callbackstr
   * @returns
   */
  SetUpdateSliderCB: (cbstr: string) => void
}

export interface IWASMModuleToothEruption {
  /**
   * 切换模式 PickDome或者默认模式（PickTooth）
   * @param isPickDome
   * @returns
   */
  ChangePickDomeMode: (isPickDome: boolean) => void

  /**
   * 设置回调
   * 期望的JS函数拥有这样的形式：
Js func( visible, toothId, x, y, toRight, toUp )
第一个参数控制显示或隐藏，如果是false，后面的参数就不用理会我传递的值。
如果是true，就会涉及到widget上面的牙齿编号以及widget位置。
牙齿编号我听tammy说setting部分还没有，之后前端会补上，我先传递一个整数值。你们先转换成UL1的形式，后面有了setting部分，再依据前端的信息处理成对应的编号系统即可。
最后四个参数详细解释一下，和之前UD上的位置算法有关系。
   * @param cbStr
   * @returns
   */
  SetShowPickModeDialogCB: (cbStr: string) => void

  /**
   * Invoke Sync function of Dome
   * 在最后一步，第一步，移动dome后，正常播放dome会动。点击这个按钮后，再播放，dome位置不变。
   * @returns
   */
  OnSyncButtonDown: () => void

  /**
   * Sync 是否是static状态
   */
  IsStatic: () => boolean
}

export interface ICPLUSArrayObject<T> {
  size: () => number
  get: (idx: number) => T
}

/**
 * 移牙相关功能模块
 */
export interface IWASMMoveToothModule {
  /**
   * 锁定磨牙
   *
   *   We will lock mollar teeth after go to setup page in Copa version. But the locked tooth was not gray
   *   and no locker 3D button following tooth. It's a weird requirement.
   *   The action will happen after all neccessary modules were opened.
   *   It's called in InitFinishedCallBack of TypeScript.
   *   carePick: change color with caring about pick event. (default: false)
   *   toLock: change status to locked or unlocked
   * @returns
   */
  ChangeLastTwoMollarsStatus: (
    archType: EShowArchType,
    carePick: boolean,
    toLock: boolean,
  ) => void

  /**
   * 取消选中牙齿的选中状态
   * @returns
   */
  CancelPickTooth: () => void

  /**
   * 获取选中牙齿的ID
   * @returns
   */
  GetPickedToothId: () => number
  /**
   * 获取牙齿的lock状态
   * @returns
   */
  GetToothLockStatus: (toothID: number) => boolean
  /**
   * 关闭/打开的牙齿move功能
   * @returns
   */
  LockTooth: (toothId: number, locked: boolean) => void

  /**
   * reset tooth movement
   * @returns
   */
  ResetToothMovement: () => void
  /**
   * is show SuperImpose
   * @returns
   */
  ShowSingleSuperImpose: (isShow: boolean) => void
  /**
   * 拔掉这颗牙
   * @returns
   */
  PulloutATooth: (toothID: number) => void

  /**
   * 查询选中牙齿的状态-是否是Crown ...
   * @param toothID
   * @returns boolean
   */
  GetToothCrownStatus: (toothID: number) => boolean
  GetToothImplantStatus: (toothID: number) => boolean
  GetToothBridgeStatus: (toothID: number) => boolean
  GetToothPonticStatus: (toothID: number) => boolean
  GetToothPrimaryStatus: (toothID: number) => boolean
  GetToothEruptStatus: (toothID: number) => boolean
  CanShowToothWidget: () => boolean

  GetRotateControlStatus: () => boolean
  GetDistanceRotateValues: (toothID: number) => ICPLUSArrayObject<number>

  /**
   * 这个决定 可不可以移动
   * @param isReadOnly
   * @returns
   */
  SetViewMode: (isReadOnly: boolean) => void

  /**
   * 是否绕中心旋转
   * @param isEnabled
   * @returns
   */
  SetRotateCenter: (isEnabled: boolean) => void

  /**
   * 根据面板不同类型输入值移动牙齿牙齿
   * @param val
   * @returns 是否移动了
   */
  MoveWithDistalOrMesial: (val: number) => boolean
  MoveWithBuccalOrLingual: (val: number) => boolean
  MoveWithExtrusionOrInstrusion: (val: number) => boolean
  MoveWithTorqueDirection: (val: number) => boolean
  MoveWithTippingDirection: (val: number) => boolean
  MoveWithRotationDirection: (val: number) => boolean

  /**
   * 移牙 panel 相关按钮功能
   * @returns
   */
  QuickDistalButtonDown: () => void
  SlowDistalButtonDown: () => void
  QuickMesialButtonDown: () => void
  SlowMesialButtonDown: () => void
  QuickBuccalButtonDown: () => void
  SlowBuccalButtonDown: () => void
  QuickLingualButtonDown: () => void
  SlowLingualButtonDown: () => void
  QuickExtrusionButtonDown: () => void
  SlowExtrusionButtonDown: () => void
  QuickIntrusionButtonDown: () => void
  SlowIntrusionButtonDown: () => void
  QuickInclinationLingualButtonDown: () => void
  SlowInclinationLingualButtonDown: () => void
  QuickInclinationBuccalButtonDown: () => void
  SlowInclinationBuccalButtonDown: () => void
  QuickAngulationDistalButtonDown: () => void
  SlowAngulationDistalButtonDown: () => void
  QuickAngulationMesialButtonDown: () => void
  SlowAngulationMesialButtonDown: () => void
  QuickRotationDistalButtonDown: () => void
  SlowRotationDistalButtonDown: () => void
  QuickRotationMesialButtonDown: () => void
  SlowRotationMesialButtonDown: () => void

  /**
   * 移牙 panel checkbox function
   * @param isCrown
   * @param isImplant
   * @param isBridge
   */
  CrownBoxClicked: (isCrown, isImplant, isBridge) => void
  ImplantBoxClicked: (isCrown, isImplant, isBridge) => void
  BridgeBoxClicked: (isCrown, isImplant, isBridge) => void

  /**
   * 移牙相关回调缺少文档和注释
   * @param cbStr
   * @returns
   */
  SetForbidMoveMollarToothCB: (cbStr: string) => void
  SetMoveNonFinalToothCB: (cbStr: string) => void
  SetOnToothClickedInViewModeCB: (cbStr: string) => void
  SetShowToothInfoDialogCB: (cbStr: string) => void
  SetShowMidificationHideStageCB: (cbStr: string) => void
  SetRecoverStageBarUICB: (cbStr: string) => void
  SetMoveToothLimitWaringCB: (cbStr: string) => void
}

export interface IWASMWrapInstance {
  /**
   * 获取MoveTooth模块
   * @returns
   */
  GetMoveToothModule: () => IWASMMoveToothModule
  /**
   * setSpacecheckVisibility（IPRSpc）
   * @param isVisible
   * @returns
   */
  ShowCollision: (isVisible: boolean) => void

  /**
   * 设置当前版本类型
   * @param thing
   * @returns
   */
  SetVersionType: (thing: string) => void

  /**
   * 是否显示网格层
   * @param isVisible
   * @returns
   */
  ShowGridLayer: (isVisible: boolean) => void

  /**
   * 是否显示网格层Bolton
   * @param isVisible
   * @returns
   */
  BoltonDisplay: (isVisible: boolean) => void

  /**
   * show point to point
   * @param isVisible
   * @returns
   */
  ShowPointToPoint: (isVisible: boolean) => void

  /**
   * 是否显示Superimpose,以及相关设置
   * @param isVisible
   * @returns
   */
  ShowSuperimpose: (isVisible: boolean) => void
  /**
   * SetSuperimposeOpacity
   * @param opacity 0-1
   */
  SetSuperimposeOpacity: (opacity: number) => void
  /**
   * @param isUpper 0:upper 1:lower
   * @param stepIndex stage number
   * @param txNum current TxNumber
   */
  SetSuperimposeStage: (isUpper, stepIndex, txNum) => void
}

export interface IWASMAttachmentmodel {
  GetToothAttachmentCount: (toothId: number) => number
  GetAttachmentInfo: (
    toothId: number,
    index: number,
    isRetainer?: boolean,
  ) => any
  BAttachmentOnLingualSide: (attachmentInfo: any) => boolean

  /**
   * input which teeth're been token in scanbutton page
   * @param stringOfToothIDArray     "[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]"
   * @returns output json string of elasticjson file
   */
  GetElastic: (stringOfToothIDArray: string) => string

  /**
   * input which teeth're been token in scanbutton page
   * @param stringOfToothIDArray     "[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]"
   * @returns
   */
  SaveElasticControlPointsToJson: (stringOfToothIDArray: string) => void

  /**
   * get the first stage when add attachment
   * @param archType
   * @returns
   */
  GetAttachmentFirstStage: (archType: string) => number
}

export interface IWASMAttachmentModule {
  GetAttachmentModel: () => IWASMAttachmentmodel
  SetAttachmentSelectAbleType: (val: string) => void
  SelectLibAttachment: (attId: number) => void
  AddAllAttachment: (val: 0 | 1) => void
  AddAllBiteRamp: () => void
  SetAttachmentSettingData
  AttachTempAttachmentsAuto: (archType: string, option: any) => void
  /**
   * deleteSelectedAttachment
   * @returns
   */
  DeleteAttachment: () => void
  CleanAllAttachment: () => void
  Getm_CurSelectedAttachToothID: () => number
  AddTempAttachemnt: (toothId: number, optionType: number) => void

  /**
   * 功能：拾取的时候或者放置的时候获取拾取或放置的elastic的起始和终止int值；（如果返回-1，-1；内部获取就存在异常，正常情况是有值，这时可以选择框什么都不显示吧）；
   * @returns
   */
  GetElasticsStartAndEndStageValue: () => any

  /**
   *  第一个参数传入起始int值，第二个参数传入终止值，第三个参数（0：表示修改start，1：表示修改end，因为对应的变化不一样）；最后会返回vector,里面两个int
   * @param startStageValue
   * @param endStageValue
   * @param startOrEnd
   * @returns
   */
  ChangeStartAndEndStageValue: (
    startStageValue: number,
    endStageValue: number,
    startOrEnd: number,
  ) => any

  /**
   * cancle select elastics callback
   * @param callback
   * @returns
   */
  SetCancleSelectAttachmentCB: (callback: string) => void
}

export interface IWASMBiteRampModule {
  /**
   * 打开/关闭BiteRamp界面功能
   * @param enable
   * @returns
   */
  SetOperationEnable: (enable: boolean) => void

  /**
   * 控制是否显示height面板
   * @param enable
   * @returns
   */
  SetPlaneVisiable: (enable: boolean) => void

  /**
   * 对6-11号牙齿添加biteramp
   * @param toothID 6-11
   * @returns 添加失败会返回false
   */
  AutoAddBiteRampToTooth: (toothID: number) => boolean

  /**
   * 取消打勾，删除牙齿上的bite ramp
   * @param toothID 6-11
   * @returns
   */
  DeleteBiteRamp: (toothID: number) => void

  /**
   * 获取牙齿是否添加了biteramp
   * @param toothid 6-11
   * @returns
   */
  IsToothHasBiteRamp: (toothid: number) => boolean

  /**
   * 设置biteramp开始显示的stage
   * @param startStage startStage必须小于endStage
   * @returns
   */
  ChangeStartStage: (startStage: number) => void

  /**
   * 设置biteramp结束显示的stage
   * @param endStage
   * @returns startStage必须小于endStage
   */
  ChangeEndStage: (endStage: number) => void

  /**
   * 获取biteramp开始显示的stage
   * @returns
   */
  GetStartStage: (archType: string) => number
  /**
   * 获取biteramp结束显示的stage
   * @returns
   */
  GetEndStage: (archType: string) => number

  /**
   * 设置biteramp的显示/隐藏
   * @param bVisiable
   * @returns
   */
  SetBiteRampVisiable: (bVisiable: boolean) => void

  /**
   * 同步牙齿高度到面板
   * @returns
   */
  SyncBiteRampHeightToPlane: () => void

  /**
   * 显示/隐藏一个三颗牙齿的下颌,默认隐藏
   * @param visible
   * @returns
   */
  ShowOppositeOverlay: (visible: boolean) => void
}

/**
 * IWASMInitialFinalDisplayModule
 */
export interface IWASMInitialFinalDisplayModule {
  /**
   * OpenOcclusalView
   * @returns
   */
  OpenOcclusalView: () => void
  /**
   * set zoom
   * @param val
   * @returns
   */
  ZoomByUI: (val: number) => void
  /**
   * set callback of zoom that from mouse event
   * @param callbackString
   * @returns
   */
  SetUpdateSliderCB: (callbackString: string) => void
  /**
   * set range of zoom
   * @param val
   * @returns
   */
  SetMinScaleValue: (val: number) => void
  SetMaxScaleValue: (val: number) => void
}

export interface IWASMMainWindowModule {
  SetMinScaleValue: (val: number) => void
  SetMaxScaleValue: (val: number) => void
  ZoomAutomatic: (val: number) => void
  SetUpdateSliderCB: (cbName: string) => void
}

export interface IWASMModuleManager {
  SwitchBiteRampModule: (isEnabled: boolean) => void
  SwitchMainWindowModule: (isEnabled: boolean) => void
  SwitchUndoOrRedoModule: (isEnabled: boolean) => void
  /**
   * 开关 Tooth Eruption module
   * @param isEnabled
   * @returns
   */
  SwitchModuleToothEruption: (isEnabled: boolean) => void
  /**
   * 打开attachmentModule
   * @param isShow
   * @returns
   */
  SwitchAttachmentModule: (isShow: boolean) => void
  SwitchBoltonDisplayModule: (isEnabled: boolean) => void
  SwitchModuleOverlay: (isEnabled: boolean) => void
  SwitchFeatureEditModule: (isEnabled: boolean) => void
  GetModuleToothEruption: () => IWASMModuleToothEruption
  SetGridActorsColor: (r1, g1, b1, a1, r2, g2, b2, a2, r3, g3, b3, a3) => void

  ToggleLowerArchMove: (isShow: boolean) => void
  OnGMAttachmentButtonDown: (num: EArchType) => void
  SwitchStagingPercent: (isEnabled: boolean) => void
  GetStagingPercent: () => any
  GetAttachmentModule: () => IWASMAttachmentModule
  GetBoltonDisplayModule: () => any
  GetSplitScreenModule: () => any
  IsSplitScreenOpened: () => boolean
  GetMainWindowModule: () => IWASMMainWindowModule
  GetModuleOverlay: () => any
  GetFeatureEditModule: () => any

  /**
   * 开关移牙选中牙齿
   * @param isEnabled
   * @returns
   */
  SwitchModuleMoveTooth: (isEnabled: boolean) => void

  /**
   * control move tooth module's interactivity, we can't close the module sometimes.
   * @param isEnabled
   * @returns
   */
  SetModuleMoveToothInteroperable: (isEnabled: boolean) => void

  /**
   * @param enabled isEnabled
   */
  SwitchInitialFinalDisplayModule: (isEnabled: boolean) => void
  /**
   * get initialfinal module object
   * it's must be invoke after SwitchInitialFinalDisplayModule is enabled
   */
  GetInitialFinalDisplayModule: () => IWASMInitialFinalDisplayModule

  SwitchRefinementDisplayModule: (isEnabled: boolean) => void
  GetRefinementDisplayModule: () => any

  SwitchAutoSetupModule: (isEnabled: boolean) => void
  GetAutoSetupModule: () => any

  TogglePolyPlaneCut: (isEnabled: boolean) => void
  GetPolyPlaneCutModule: () => any
  ToggleSurfaceCutting: (isEnabled: boolean) => void
  GetSurfaceCuttingModule: () => any
  SwitchModulePropOnHover: (isHover: boolean) => void

  SwitchModuleFastSmartCircle: (onOff: boolean) => void
  SwitchModuleFineTuneCircle: (onOff: boolean) => void

  GetModulesConfigurator: () => any

  GetBiteRampModule: () => IWASMBiteRampModule

  /**
   * 打开SelectToothInOrder模块
   * @returns
   */
  SwitchModuleSelectToothInOrder: (onOff: boolean) => void
  GetModuleSelectToothInOrder: () => IWASMDesignModuleSelectInOrder
  SwitchModuleTreatView: (onOff: boolean) => void
  GetModuleTreatView: () => IWASMTreatView

  SwitchModuleOrientationAdjustment: (onOff: boolean) => void
  GetModuleOrientationAdjustment: () => IWASMOrientationAdjustment

  SwitchRelabelModule: (onOff: boolean) => void
  GetRelabelModule: () => any
  ToggleRelabel:(onOff: boolean) => void
  GetToothLabelCorrectionForRelabelModule: () => any
  FreeRelabelModuleAfterFeature: () => void
  ToggleSpaceCorrect: (isShow: boolean) => void
  GetSpaceCorrectionModule: () => any
}

/**
 * Order界面选牙模块（标记或者获取哪些牙齿被选中，并设置颜色）
 */
interface IWASMDesignModuleSelectInOrder {
  /**
   * open后，先设置选中牙齿的颜色 setPickedToothColor( r,g,b) , double 的
然后设置已经选中的牙齿，比如2，5，7，8， 那么循环调用 setPickedTooth(toothId) 
   * @param r 
   * @param g 
   * @param b 
   * @returns 
   */
  setPickedToothColor: (r: number, g: number, b: number) => void

  /**
   * open后，先设置选中牙齿的颜色 setPickedToothColor( r,g,b) , double 的
然后设置已经选中的牙齿，比如2，5，7，8， 那么循环调用 setPickedTooth(toothId) 
   * @param toothId 
   * @returns 
   */
  setPickedTooth: (toothId) => void

  /**
   * get teeth which were selected.
   * @returns
   */
  getSelectedToothIds: () => CPlusArray<number>
}

export interface IWASMModule {
  wrapInstance: IWASMWrapInstance
  VersionType: IVertionType
  ToothColorType: any
  AddOptionType: any
  AttachmentSelectAbleType: IAttachmentSelectAbleType
  ArchType: IWASMArchType
  FS: any
  HEAPU8: any
  HEAP32: any
  _malloc: (cacheSize: number) => number
  getWrapperIns: () => any
  /**
   * preview interfaces
   * @returns
   */
  getScanPreviewWin: () => IPreviewModule
  _scanPreview: (title: string) => void
  /**
   * cancel rendering loop
   * e.g.     wasmModule.module._cancel_em_loop();
   * @returns
   */
  _cancel_em_loop: () => void
  setPreviewColor: (
    c1r: number,
    c1g: number,
    c1b: number,
    c2r: number,
    c2g: number,
    c2b: number,
  ) => void
  /**
   * fill hole
   * wasmModule.module._autoFillHoles(wasmModule.module.allocateUTF8('test/pre/arch_u.stl'), wasmModule.module.allocateUTF8('test/pre/fillready_arch_u.stl'))
   */
  _autoFillHoles: (oldPath: string, newPath: string) => boolean
  allocateUTF8: (str: string) => string

  /**
   * 该接口被弃用了，使用resourcesSynchronization.deleteFile()删除指定文件
   * @param upOrLowerFlag
   * @returns
   */
  DeleteMtcFiles: (upOrLowerFlag: 0 | 1) => boolean
  SaveMtcFileToRaw: (path: string, upOrLowerFlag: 0 | 1) => boolean
  SaveMtcFileToSettingData0: (path: string, upOrLowerFlag: 0 | 1) => boolean
  /**
   * 将虚拟目录下的stl保存到指定文件夹下面的mtc
   * @param archfile
   * @param filePathInCase
   * @returns
   */
  SaveMtcFileToFolder: (archfile: string, filePathInCase: string) => boolean

  /**
   * presetup
   * @returns
   */
  getUNewPreSetUpWin: () => any

  /**
   * opencase workflow
   * befor invoking this need do cancel_em_loop
   */
  _setup: (title: string) => void
  /**
   * presetup
   * e.g. 
   *    const u = wasmModule.module.allocateUTF8('test/pre/fillready_arch_u.stl');
        const l = wasmModule.module.allocateUTF8('test/pre/fillready_arch_l.stl');
        wasmModule.module._newpresetup(u, l);
  */
  _newpresetup: (
    upperSTLPath: string,
    lowerSTLPath: string,
    title: string,
  ) => void
  _freePreview: () => void
}

export interface IWASMArchModule {
  /**
   * 返回所有牙齿的ID Array对象，除了missing tooth和 extract tooth .包括了特俗牙齿(5XX , 7XX)
   * @returns
   */
  GetToothIds: () => CPlusArray<number>

  /**
   * 返回已有的所有牙齿的ID Array对象，除了missing tooth。包括了特俗牙齿(5XX , 7XX)
   * @returns
   */
  GetNormalAndPulloutToothIds: () => CPlusArray<number>
}

export interface IWasmMouthModule {
  /**
   * get existing arch type
   * @returns
   */
  GetArchsExistInfo: () => EShowArchType
  /**
   * get toothModel by toothID
   * @param toothId
   * @param isGetMissingTooth
   * @param isGetPonticTooth
   */
  GetToothModelByToothId: (
    toothId: number,
    isGetMissingTooth: boolean,
    isGetPonticTooth: boolean,
  ) => IWasmToothModule

  GetArchModel: (archType: string) => IWASMArchModule

  /**
   * 查询某个牙号的牙齿是否是拔牙，是true则表示拔牙
   * @param toothId
   * @returns
   */
  GetToothExtractStatus: (toothId: number) => boolean
  GetBigSpace: () => boolean
}

interface IWasmToothModule {
  GetToothWidth: () => number
  GetToothCrownStatus: () => boolean
  GetToothImplantStatus: () => boolean
  GetToothBridgeStatus: () => boolean
  GetRotateControlStatus: () => boolean
  GetToothPrimaryStatus: () => boolean
  GetDistanceRotateValues: (toothID: number) => any
}

/**
 * WASM StageController模块接口
 */
export interface IWASMStageController {
  //-------------------Treatment interfaces---------------------
  /**
   * 判断当前方案是否改变
   * @returns
   */
  GetTxDataChanged: () => boolean
  /**
   * 设置当前方案是否改变
   * @returns
   */
  SetTxDataChanged: (changed: boolean) => void
  /**
   * 切换方案
   * @returns
   */
  AddTreatment: () => boolean
  /**
   * 切换方案
   * @returns
   */
  ChangeTreatment: (txIndex: number) => boolean

  /**
   * 获取当前方案的索引
   * @returns
   */
  GetCurTx: () => number

  /**
   * 检测方案二是否存在
   * @returns boolean
   */
  Tx2Exist: () => boolean

  /**
   * 保存当前方案
   * @returns
   */
  SaveCurTx: () => boolean

  /**
   *
   * @param archType EArchType
   * @param txIndex 要删除的方案Index,从1开始计数
   */
  DelTx: (txIndex: number) => boolean

  //-------------------Stage interfaces-------------------------------

  /**
   * Change Stage 切换牙齿到其他Stage
   * @param stageIndex
   * @param archType
   * @returns
   */
  ChangeStage: (stageIndex: number, archType: EArchType) => void

  /**
   * 获取当前的stage的Index
   * @param archType
   * @returns
   */
  GetCurrentStageIndex: (archType: EArchType) => number

  /**
   * 删除普通节点
   * @param archType
   * @param stepIndex 准备删除的关键节点的index
   * @returns
   */
  DeleteNode: (archType: EArchType, stepIndex: number) => boolean

  /**
   * 添加普通节点
   * @param archType    牙颌类型
   * @param stepIndex   准备添加节点的位置
   * @param bMore       是否在最后
   * @returns
   */
  AddNormalNode: (
    archType: EArchType,
    stepIndex: number,
    bMore: boolean,
  ) => boolean

  //----------------------Velocity interfaces-------------------------
  /**
   * 让上颌下颌步长一致
   * @returns
   */
  VelocityUpAndDownArchEqual: () => boolean

  /**
   * 上颌Velocity 增加步长操作
   * @returns
   */
  VelocityUpArchPlus: () => boolean
  /**
   * 上颌Velocity 减少步长操作
   * @returns
   */
  VelocityUpArchMinus: () => boolean

  /**
   * 下颌Velocity 增加步长操作
   * @returns
   */
  VelocityDownArchPlus: () => boolean

  /**
   * 下颌Velocity 减少步长操作
   * @returns
   */
  VelocityDownArchMinus: () => boolean

  /**
   * 撤销上颌Velocity 的操作
   * @returns
   */
  VelocityUpArchRestore: () => boolean

  /**
   * 撤销下颌Velocity 的操作
   * @returns
   */
  VelocityDownArchRestore: () => boolean

  /**
   * ???????
   * @param archType
   * @param movePace
   * @param rotatePace
   * @param startNum
   * @param endNum
   * @returns
   */
  VelocityModifyPaceValue: (
    archType: EArchType,
    movePace: number,
    rotatePace: number,
    startNum: number,
    endNum: number,
  ) => void

  /**
   * ?????
   * @param archType
   * @returns
   */
  GetDefaultSpaceAndRotateValue: (archType: EArchType) => [number, number]

  //-------------------InitialFinalScreenshot----------------------------

  GetInitAndFinalStagePNG: (enabled: boolean) => void
  /**
   * 保存手动移牙后方案数据
   * 前端调用后触发wasm数据保存，保存数据后js获取数据文件，并通过zip压缩返回给前端
   * @returns
   */
  SaveSetUpResult: () => boolean

  /**
   * 保存Autosetup后方案数据的结果
   * @returns
   */
  SaveAutoSetUpResult: () => boolean

  //-------------------Unknown---------------------
  /**
   * Byte 和IPR 这个是一个状态设置，和Frank 那边的一个前端分支需求有关，wasm 要获取那两个按键状态
   * ???
   * @param enabled
   * @returns
   */
  UpdateByteButtonStatus: (enabled: boolean) => void
  UpdateIPRButtonStatus: (enabled: boolean) => void
  /**
   * 这个是邹恒以前要的一个接口，清空 牙齿的变化信息
   * @param toothId
   * @param archType
   * @returns
   */
  ClearToothStageTransInfo: (toothId: number, archType: EArchType) => boolean

  /**
   * Rename a exist treatment plan
   * @param txIndex tx Index, count from 1
   * @param txName new tx name
   * @returns
   */
  RenameTX: (txIndex: number, txName: string) => boolean

  /**
   * Get treatment plan name
   * @param txIndex tx Index, count from 1
   * @returns
   */
  GetTXName: (txIndex: number) => string

  OpenOrClosetoothMoveModule: (isOpen: boolean) => void

  /**
   * Get staging Nodes
   */
  GetNodes: (archType: EArchType, txIndex: number) => any

  /**
   * 设置回调，当移牙做了PA(passive aligner) 或者 IPR 需要提示用户是否除去PA和IPR
   * 回调函数的参数: (hasIPR:0|1,hasPA:0|1)=>void
   * @param cbStr
   * @returns
   */
  SetExistIprAndPassiveWarningCB: (cbStr: string) => void
  /**
   * 用户选择确认或取消需要调用此接口通知WASM,
   * 确认则删除PA和IPR，取消则什么都不做。
   * @param confirmOrCancel
   * @returns
   */
  DealWithExistIprAndPassiveWarning: (confirmOrCancel: boolean) => void

  /**
   * set the stage number for show attachment
   * @param stepIndex
   * @returns
   */
  SetAttachStep: (stepIndex: number) => void

  /**
   * set the attachment panel status to wasm
   * @param isOn
   * @returns
   */
  setAttachBtnDown: (isOn: boolean) => void

  /**
   * set biteJump type
   * @param biteJumpType
   * @returns
   */
  SetBiteJumpType: (biteJumpType: EBiteJumpLocationType) => void

  /**
   * get the result whether the case did global movement
   * @returns
   */
  IsDownArchBiteMoved: () => boolean

  /**
   * equal all Passive Aligner nodes
   * @returns
   */
  AddPassiveAligner: () => void

  /**
   * delete all Passive Aligner nodes
   * @returns
   */
  DelPassiveAligner: () => void

  /**
   * add a Passive Aligner node at stageIndex
   * @param archType
   * @param stageIndex
   * @returns
   */
  addPassiveNode: (archType: number, stageIndex: number) => void

  /**
   * delete the Passive Aligner node at stageIndex using the current teeth state
   * @param archType
   * @param stageIndex
   * @returns
   */
  deletePassiveNode: (archType: number, stageIndex: number) => void

  /**
   * Set a callback to report playback progress when playing GM
   * The parameters of the callback function: (progress:0.0 - 1.0)=>void
   * @param cbStr
   * @returns
   */
  setPlayGMCB: (cbStr: string) => void

  /**
   * play GM animation
   * @returns
   */
  playGMAnimation: () => number

  /**
   * set biteJump type and play GM
   * @param biteJumpType
   * @returns
   */
  setBiteJumpTypePlayGM: (biteJumpType: EBiteJumpLocationType) => void

  /**
   * get biteJump type
   * @returns
   */
  getBiteJumpType: () => number
}

export interface IWASMTreatView {
  /**
   * compare for txs, start compare
   */
  StartComparison: (leftTreatName: string, rightTreatName: string) => boolean
  /**
   * compare for refinement
   * @param leftTreatName
   * @param rightTreatName
   * @returns
   */
  RefinementHistory: (leftTreatName: string) => boolean
  /**
   * set background picture for compare view
   * @param viewId left: 0  right: 1
   * @param picture picture name
   */
  SetBackgroundPicture: (viewId: number, picture: string) => void
  /**
   * switch refinement history left view when compare open
   * @param leftTreatName
   * @returns
   */
  SwitchRefinementHistory: (leftTreatName: string) => void
  /**
   * switch the tx plan for compare
   * @param viewId left:0, right:1
   * @param planIndex 1,2
   * @returns
   */
  SwitchTxPlan: (viewId: number, planIndex: number) => boolean
  /**
   * compare for txs, get compare nodes
   */
  GetNodes: (viewId: number, archIndex: number) => any
  /**
   * compare for txs, start compare
   */
  PlayFrame: (viewId: number, stageIndex: number) => void

  GetHistoryDataInfo: (viewId: number, archIndex: number) => string

  /**
   * set the min zoom value for refinement view
   * @param arg
   * @returns
   */
  SetMinScaleValue: (arg: number) => void

  /**
   * set the max zoom value for refinement view
   * @param arg
   * @returns
   */
  SetMaxScaleValue: (arg: number) => void
  /**
   * Get the zoom value in the current refinementview from wasm
   * @param callback
   * @returns
   */
  SetUpdateSliderCB: (callback: any) => void

  /**
   * set the zoom value for refinement view
   * @param arg
   * @returns
   */
  ZoomAutomatic: (arg: any) => void

  /**
   * Get the view gm's enable
   * @param viewID
   * @returns
   */
  IsHaveGM: (viewID: number) => boolean

  /**
   * Play gm
   * @param viewID
   * @returns
   */
  PlayGM: (viewId: number) => number

  /**
   * Set gm to final
   * @param viewID
   * @returns
   */
  SetGMToFinal: (viewId: number) => void

  /**
   * Set GM Type
   * @param viewID
   * @param type
   * @returns
   */
  SetBiteJumType: (viewID: number, type: number) => void

  /**
   * Update LowerArch Actor After Set GM Type
   * @param viewID
   * @returns
   */
  UpdateLowerArchActorAfterSetGMType: (viewId: number) => void
}

export interface IWASMOrientationAdjustment {
  SaveOrientation: () => void
}
