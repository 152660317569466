/* eslint-disable */
import * as React from "react"
import { FC, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Container,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tab,
  Tabs,
  TextField,
  Backdrop,
} from "@mui/material"
import {
  initialfinal,
  stageControl,
  toothMovement,
  EBiteJumpLocationType,
  viewControlInTreatment,
  caseManagement,
  history,
} from "@/gluelayer"
import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { treatmentPlan } from "@/gluelayer/core/modules/treatmentplan"

import downSvg from "../../assets/bottomToolBar/stage/downDirect.svg"
import initalSvg from "../../assets/bottomToolBar/stage/initalBtn.svg"
import initalDisSvg from "../../assets/bottomToolBar/stage/initalDisBtn.svg"
import movementSvg from "../../assets/bottomToolBar/stage/movementBtn.svg"
import movementDisSvg from "../../assets/bottomToolBar/stage/movementDisBtn.svg"
import nextSvg from "../../assets/bottomToolBar/stage/next.svg"
import nextDisSvg from "../../assets/bottomToolBar/stage/nextDis.svg"
import playSvg from "../../assets/bottomToolBar/stage/play.svg"
import previousSvg from "../../assets/bottomToolBar/stage/previous.svg"
import previousDisSvg from "../../assets/bottomToolBar/stage/previousDis.svg"
import reportSvg from "../../assets/bottomToolBar/stage/reportBtn.svg"
import reportDisSvg from "../../assets/bottomToolBar/stage/reportDisBtn.svg"
import sideSvg from "../../assets/bottomToolBar/stage/sideBySide.svg"
import sideDisSvg from "../../assets/bottomToolBar/stage/sideBySideDis.svg"
import stopSvg from "../../assets/bottomToolBar/stage/stop.svg"
import stopDisSvg from "../../assets/bottomToolBar/stage/stopDis.svg"
import tabSvg from "../../assets/bottomToolBar/stage/tabBtn.svg"
import upSvg from "../../assets/bottomToolBar/stage/upDirect.svg"
import upDisSvg from "../../assets/bottomToolBar/stage/upDirectDis.svg"
import biteJump from "../../assets/bottomToolBar/stage/biteJump.svg"
import biteJumpDis from "../../assets/bottomToolBar/stage/biteJumpDis.svg"

import helpSvg from "../../assets/bottomToolBar/stage/help.svg"
import starIcon from "../../assets/bottomToolBar/stage/star.svg"
import { UButton } from "@/components/index"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import StageBar from "../StageBar/stageBar"
import ToolButton from "../ToolBar/toolButton"

import { toggleProps } from "./type"

import "./toggle.scss"
import { planStarTmp } from "./column"
import StageTxTab from "../StageBar/stageTxTab"
import StatusButton from "../StageBar/statusButton"
import PassiveAligner from "../StageBar/passiveAligner"

// timer
let timer: string | number | NodeJS.Timeout | undefined
interface BtnObj {
  isBool: boolean
  type: string
}
const { cancelPickTooth } = toothMovement
const { setInitialFinalEnabled } = initialfinal
const bottomNoRefinement = 200
const bottomRefinement = 222
const allNoRefinement = 248
const allRefinement = 270

const {
  setAttachmentVisibility,
  setViewType,
  showArchMode,
  setSpacecheckVisibility,
  setGridVisibility,
  setOcclusionVisibility,
  setSuperimposeVisibility,
  setOverlayVisibility,
  hoverToothToggle,
} = viewControlInTreatment

const {
  addTreatment,
  delTx,
} = caseManagement

const Toggle: FC<toggleProps> = ({
  preparation,
  setReportShow,
  setopenMovement,
  setInitalShow,
  initalShow,
  openMovement,
  isReady,
  setStageObj,
  planList,
  setCurrentPlan,
  currentPlan,
  renamePlanFunction,
  deletePlanFunction,
  attachmentObj,
  setIsStageFold,
  refinementKey,
  setRefinementKey,
  isHasUpAndlow,
  isEdit,
  arLimitShow,
  setStagebarHeight,
  maxRefine,
  setMaxRefine,
  attachStage,
  hasBite,
  isRANL,
  stageBarWidth,
  setStageBarWidth,
  getTxPlans,
  stageMaxWidth,
  showTxTab
}) => {

  const { switchViewEdit, isCreateNewRefinement } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const { t } = useTranslation("common")

  // 是否折叠,默认折叠状态(Control whether to fold or not)
  const [isFold, setIsFold] = useState<boolean>(true)
  // plan anchor
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  // bite jump anchor
  const [biteJumpAnchorEl, setBiteJumpAnchorEl] =
    React.useState<HTMLButtonElement | null>(null)

  // control btn status
  const [btnObj, setBtnObj] = useState<BtnObj>({
    isBool: true,
    type: "stop",
  })
  const stageBarContainerBottomWrapRef = useRef(null)
  // const [stageBarWidth, setStageBarWidth] = useState<number>(100)
  // deleteTx dialog show or hide
  const [deleteTxDialogShow, setDeleteTxDialogShow] = useState<boolean>(false)
  const [deleteTxWarning, setDeleteTxWarning] = useState<number>(-1)//-1:close 0:delete tx 1 1:delete tx 2
  const [addEmptyTxWarning, setAddEmptyTxWarning] = useState<boolean>(false)
  const [noPermitGoPassiveAlignerWarning, setNoPermitGoPassiveAlignerWarning] = useState<boolean>(false)
  const [passiveAlignerClickedStatus, setPassiveAlignerClickedStatus] = useState<boolean>(false)
  // renameTx dialog show or hide
  const [renameTxDialogShow, setRenameTxDialogShow] = useState<boolean>(false)
  // current Tx name
  const [reNameInputText, setReNameInputText] = useState<string>(
    currentPlan.txName,
  )
  // renameInput error
  const [isRenameError, setIsRenameError] = useState<boolean>(false)
  // refinement bool
  const [isRefinement, setIsRefinement] = useState<boolean>(false)
  // help Backdrop show or hidden
  const [helpOpen, setHelpOpen] = useState<boolean>(false)
  const [uiShowType, setUiShowType] = useState<string>("")
  // click tab
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget.parentNode?.parentNode)
  }
  // click btns
  const clickToPlay = (type: string) => {
    if (type === "play") {
      setBtnObj({
        type: btnObj.type !== "play" ? "play" : "stop",
        isBool: !btnObj.isBool,
      })
    } else {
      setBtnObj({
        type,
        isBool: !btnObj.isBool,
      })
    }
  }
  //close listItems
  const closeListItems = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const popoverId = open ? "simple-popover" : undefined
  // open bite jump listItems
  const openBiteJumpListItems = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()
    setBiteJumpAnchorEl(event.currentTarget)
  }

  // bite jump close listItems
  const closeBiteListItems = () => {
    setBiteJumpAnchorEl(null)
  }
  const biteOpen = Boolean(biteJumpAnchorEl)
  const bitePopoverId = biteOpen ? "simple-popover1" : undefined
  // click fold
  const foldClick = () => {
    setIsFold(!isFold)
    setIsStageFold(!isFold)
  }
  // 点击折叠按钮(Click the collapse button)
  const startMove = (target: number) => {
    if (!document.getElementsByClassName(`toggle`)[0]) {
      clearInterval(timer)
      return
    }
    clearInterval(timer)
    timer = setInterval(() => {
      let speed = 0
      if (!document.getElementsByClassName(`toggle`)[0]) {
        clearInterval(timer)
        return
      }
      const toggleElement = getComputedStyle(
        document.getElementsByClassName(`toggle`)[0],
      )
      const height = Math.ceil(+toggleElement.height.split("px")[0])
      if (height < target) {
        speed = isRefinement ? bottomRefinement : bottomNoRefinement
      } else {
        speed = -(isRefinement ? bottomRefinement : bottomNoRefinement)
      }
      if (target === height) {
        clearInterval(timer)
        const cavans = document.getElementById("canvas")
        canvas.setAttribute("height", cavans?.offsetHeight)
        // viewControlInTreatment.setViewType("front")
      } else {
        const toggleElement1 = document.getElementsByClassName(
          `toggle`,
        )[0] as HTMLElement
        if (speed > 0) {
          if (height + speed > target) {
            toggleElement1.style.height = `${target}px`
          } else {
            toggleElement1.style.height = `${height + speed}px`
          }
        } else {
          if (height + speed < target) {
            toggleElement1.style.height = `${target}px`
          } else {
            toggleElement1.style.height = `${height + speed}px`
          }
        }
        // const cavans = document.getElementById("canvas")
        // canvas.setAttribute("height", cavans?.offsetHeight)
        // viewControlInTreatment.setViewType("front")
      }
    }, 3)
  }
  // click deleteTx listItem
  const clickDeleteItem = () => {
    closeListItems()
    setDeleteTxDialogShow(true)
  }
  // click renameTx listItem
  const clickRenameItem = () => {
    closeListItems()
    setRenameTxDialogShow(true)
    setReNameInputText(currentPlan.txName)
  }
  // close delete tx dialog
  const closeDeleteDialog = () => {
    setDeleteTxDialogShow(false)
  }
  // click delete tx confirm
  const deleteTxClick = () => {
    deletePlanFunction()
    closeDeleteDialog()
  }
  // close rename tx dialog
  const closeRenameDialog = () => {
    setIsRenameError(false)
    setReNameInputText("")
    setRenameTxDialogShow(false)
  }
  // click rename tx confirm
  const renameTxClick = () => {
    const res = reNameInputText.replace(/^\s+|\s+$/g, "")
    if (res.length === 0) {
      setIsRenameError(true)
      return
    }
    renamePlanFunction(res)
    closeRenameDialog()
  }
  // onchange renameTx input
  const handleChangeTxName = (inputValue: string) => {
    setIsRenameError(false)
    const newValue = inputValue.replace(/[^\x00-\xff]/g, "**")
    if (newValue.length > 20) {
      return
    }
    const specialCharRegex = RegExp(
      /[(\~)(\~)(\!)(\！)(\@)(\#)(\$)(\￥)(\%)(\^)(\……)(\&)(\*)(\()(\（)(\))(\）)(\-)(\_))(\——)(\+)(\=)(\[)(\【)(\])(\】)(\{)(\})(\|))(\、))(\)(\\)(\;)(\；)(\:)(\：)(\')(\‘)(\’)(\")(\“)(\”)(\,)(\，)(\.)(\。)(\/)(\《)(\<)(\>)(\》)(\?)(\？)(\)]+/,
    )
    const hasSpecialChar = specialCharRegex.test(inputValue)
    if (hasSpecialChar) {
      return
    }
    setReNameInputText(newValue)
  }

  const addTreatmetPlanHandle = () => {
    if(stageControl.getMaxStageNodeIndex() > 0)
    {
      passiveAlignerClickedHandle(false)
      setPassiveAlignerClickedStatus(false)
      addTreatment()
    }
    else 
    {
      setAddEmptyTxWarning(true)
    }
  }

  const deleteTreatmentPlanHandle = (index) => {
    setDeleteTxWarning(index-1)
    //delTx(index)
  }

  const setCurrentPlanHandle = (curPlan) => {
    const wasmTx = treatmentPlan.getCurTx()
    const uiTx = curPlan.index
    console.info("wasmTx: ", wasmTx, "uiTx: ", uiTx)
    if(wasmTx !== uiTx) {
      caseManagement.changeTreatment(uiTx)
    }

    setCurrentPlan(curPlan)
  }

  const passiveAlignerClickedHandle = (clicked) => {
    if(clicked)
    {
      //into passive aligner
      if(stageControl.getMaxStageNodeIndex() > 0) 
      {
        setUiShowType("passive_aligner")
      }
      else 
      {
        setNoPermitGoPassiveAlignerWarning(true)
        return false
      }
    }
    else
    {
      //out passive aligner
      setUiShowType("")
    }
    return true
  }

  useEffect(() => {
    console.info("toggle isCreateNewRefinement changed:", isCreateNewRefinement)
    if(isCreateNewRefinement)
    {
      //close passive aligner
      if(passiveAlignerClickedStatus)
      {
        passiveAlignerClickedHandle(false)
        setPassiveAlignerClickedStatus(false)
      }
    }
  }, [isCreateNewRefinement]);//in refinement ui

  useEffect(() => {
    if (initalShow) {
      startMove(0)
    } else {
      if (!isFold) {
        startMove(isRefinement ? allRefinement : allNoRefinement)
      } else {
        startMove(48)
      }
    }
  }, [isFold, initalShow])
  useEffect(() => {
    if (!initalShow) {
      setInitialFinalEnabled(false)
    }
  }, [initalShow])
  // refinement key is all will close report and movement
  useEffect(() => {
    if (maxRefine && !refinementKey.includes(maxRefine.toString())) {
      setReportShow(false)
      setopenMovement(false)
    }
  }, [refinementKey])
  // plan delete and rename listItems
  const menuList = () => {
    return (
      <>
        <ListItemButton
          onClick={clickDeleteItem}
          disabled={planList.length === 1}
          key={1}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(144, 202, 249, 0.08)",
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: "unset", marginRight: "16px" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.00008 15.8333C5.00008 16.75 5.75008 17.5 6.66675 17.5H13.3334C14.2501 17.5 15.0001 16.75 15.0001 15.8333V5.83333H5.00008V15.8333ZM15.8334 3.33333H12.9167L12.0834 2.5H7.91675L7.08341 3.33333H4.16675V5H15.8334V3.33333Z"
                fill="white"
                fillOpacity="0.56"
              />
            </svg>
          </ListItemIcon>
          <ListItemText
            primary={t("treat.deleteText")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
        <ListItemButton
          key={2}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(144, 202, 249, 0.08)",
            },
          }}
          onClick={clickRenameItem}
        >
          <ListItemIcon sx={{ minWidth: "unset", marginRight: "16px" }}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.49902 14.3771V17.5021H5.62402L14.8407 8.28542L11.7157 5.16042L2.49902 14.3771ZM17.2574 5.86875C17.5824 5.54375 17.5824 5.01875 17.2574 4.69375L15.3074 2.74375C14.9824 2.41875 14.4574 2.41875 14.1324 2.74375L12.6074 4.26875L15.7324 7.39375L17.2574 5.86875Z"
                fill="white"
                fillOpacity="0.56"
              />
            </svg>
          </ListItemIcon>
          <ListItemText
            primary={t("treat.renameText")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </>
    )
  }
  // click bite jump listItem
  const clickBJItem = (str: string) => {
    stageControl.setBiteJumpType(EBiteJumpLocationType[str])
    closeBiteListItems()
  }
  // bite jump listItems
  const biteMenuList = () => {
    return (
      <>
        <ListItemButton
          onClick={() => {
            clickBJItem("EBiteJumpLocation_Start")
          }}
          key={1}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(144, 202, 249, 0.08)",
            },
          }}
        >
          <ListItemText
            primary={t("treat.biteJump.begin")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
        <ListItemButton
          key={2}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(144, 202, 249, 0.08)",
            },
          }}
          onClick={() => {
            clickBJItem("EBiteJumpLocation_All")
          }}
        >
          <ListItemText
            primary={t("treat.biteJump.throughout")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
        <ListItemButton
          key={3}
          sx={{
            py: 0,
            minHeight: 32,
            "&:hover": {
              background: "rgba(144, 202, 249, 0.08)",
            },
          }}
          onClick={() => {
            clickBJItem("EBiteJumpLocation_End")
          }}
        >
          <ListItemText
            primary={t("treat.biteJump.end")}
            sx={{
              "& .MuiTypography-root": {
                color: "#fff",
                fontSize: "14px",
              },
            }}
          />
        </ListItemButton>
      </>
    )
  }
  const handleKeyDown = (e) => {
    if (!preparation) {
      return
    }
    const elementFocused = document.activeElement

    if (e.keyCode === 32) {
      if (!isReady || arLimitShow) {
        return
      }
      if (
        elementFocused &&
        ((elementFocused.tagName.toLowerCase() === "input" &&
          elementFocused.getAttribute("type") === "text") ||
          elementFocused.tagName.toLowerCase() === "textarea")
      ) {
        return
      }
      e.preventDefault()
      clickToPlay("play")
    }
    //undo
    if (e.ctrlKey && e.keyCode === 90) {
      if (
        !isEdit
          ? true
          : !isReady ||
            initalShow ||
            arLimitShow ||
            isRANL ||
            (maxRefine && !refinementKey.includes(maxRefine.toString()))
      ) {
        return
      }
      history.undo()
    }
    //redo
    if (e.ctrlKey && e.keyCode === 89) {
      if (
        !isEdit
          ? true
          : !isReady ||
            initalShow ||
            arLimitShow ||
            isRANL ||
            (maxRefine && !refinementKey.includes(maxRefine.toString()))
      ) {
        return
      }
      history.redo()
    }
  }
  // keydown

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [
    btnObj,
    preparation,
    isReady,
    arLimitShow,
    isEdit,
    initalShow,
    isRANL,
    maxRefine,
    refinementKey,
  ])

  //stageBarContainerBottomWrapRef
  // useEffect(() => {
  //   if(){

  //   }
  // }, [
  //   stageBarWidth
  // ])
  // sx={{width: "100%", height: 200, backgroundColor: "#FF0000"}}
  return (
    <Box
      id="stageBarContainer"
      sx={{
        width: stageBarWidth,
        height: "156px",
        border: "none",
        background: "#FFFFFF00", //#82AEBB
      }}
    >
      <Container
        className={"stageBarContainerTopWrap"}
        style={{
          borderBottom: isFold ? "unset" : "1px solid #455A64",
          width: "100%",
          height: "30px",
          maxWidth: "none",
          display: "flex",
          margin: "0px",
          padding: "0px",
        }}
      >
        <div
          id="left"
          className={"topLeft"}
          style={{
            height: "30px",
            width: "calc(50% - 72px)",
            background: "transparent",
          }}
        >
          <StageTxTab 
            planList={planList} 
            currentPlan={currentPlan} 
            setCurrentPlan={setCurrentPlanHandle} 
            addTreatmetPlan={addTreatmetPlanHandle} 
            deleteTreatmentPlan={deleteTreatmentPlanHandle}
            viewEdit={switchViewEdit && !isCreateNewRefinement}
            // hideAdd={}
          >

          </StageTxTab>
          {/* <Tabs
            value={currentPlan.index}
            onChange={(event, value) => {
              planList.map((i) => {
                if (i.index === value) {
                  setCurrentPlan(i)
                }
              })
            }}
            sx={{
              marginLeft: "8px",
              "& .MuiButtonBase-root": {
                // width: "96px",
                padding: "9px 8px",
              },
              "& .MuiTab-textColorPrimary": {
                color: "#fff",
                fontSize: "14px",
                fontWeight: "500",
                position: "relative",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              },
              "& .Mui-selected": {
                color: "#90CAF9 !important",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#90CAF9",
              },
            }}
          >
            {planList.map((i, j) => {
              return (
                <Tab
                  key={i.index}
                  value={i.index}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {txplan_name === i.txName &&
                      planStarTmp.includes(case_disposition) ? (
                        <img
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "6px",
                          }}
                          src={starIcon}
                        />
                      ) : (
                        <></>
                      )}
                      <span
                        style={{ marginRight: "6px" }}
                      >{`${i.txName}`}</span>
                      <Button
                        className={"tabBtn"}
                        sx={{
                          backgroundImage: `url(${tabSvg})`,
                          display: !isEdit
                            ? "none"
                            : currentPlan.index === i.index
                            ? "inline-flex"
                            : "none",
                        }}
                        onClick={handleClick}
                      />
                      {j + 1 !== planList.length ? (
                        <div className={"driver"}></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                />
              )
            })}
          </Tabs> */}
        </div>
        <div id="center" className={"topCenter"} style={{ width: "144px" }}>
          <ToolButton
            disabled={!isReady || arLimitShow}
            data-testid="Backwards"
            bg={previousSvg}
            disabledBg={previousDisSvg}
            title={"Initial stage"}
            val={"Initial stage"}
            placement={"top"}
            isFocused={true}
            callBack={() => {
              clickToPlay("icStart")
            }}
            sxProps={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              marginTop: "0px",
            }}
          />
          <ToolButton
            disabled={!isReady || arLimitShow}
            data-testid="Play"
            bg={btnObj.type !== "play" ? stopSvg : playSvg}
            disabledBg={stopDisSvg}
            title={btnObj.type !== "play" ? "Play" : "Pause"}
            val={"play"}
            placement={"top"}
            isFocused={true}
            sxProps={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              margin: "0px 16px 0 16px",
            }}
            callBack={() => {
              clickToPlay("play")
            }}
          />
          <ToolButton
            disabled={!isReady || arLimitShow}
            data-testid="Forward"
            bg={nextSvg}
            disabledBg={nextDisSvg}
            title={"Final stage"}
            val={"Final stage"}
            placement={"top"}
            isFocused={true}
            sxProps={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              marginTop: "0px",
            }}
            callBack={() => {
              clickToPlay("icEnd")
            }}
          />
        </div>
        <Box
          className={"topRight"}
          sx={{
            width: "calc(50% - 72px)",
            height: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center"
          }}
        >
          
          {/*<Button variant="contained" sx={{height: "24px", fontSize: "11px", color: "#464646", background: "#F0F0F0"}}>
            Quick 3
          </Button>
          <Box sx={{width: "2px"}}></Box>
          */}
          <StatusButton
           text={"Passive aligner"} 
           fontSize={13} 
           radius={4} 
           width={110} 
           height={24} 
           backgroundColor={"#FFFFFFCC"} 
           clickedBackgroundColor={"#02AFEC"} 
           color={"#464646"} 
           clickedColor={"#FFFFFF"}
           onClickHandle={passiveAlignerClickedHandle}
           clicked={passiveAlignerClickedStatus}
           setClicked={setPassiveAlignerClickedStatus}
           visibility={(switchViewEdit && !isCreateNewRefinement) ? "" : "hidden"}>
            
          </StatusButton>
          {/* <Button variant="contained" sx={{height: "24px", fontSize: "11px", color: "#464646", background: "#F0F0F0"}}>
            Passive aligner
          </Button> */}
        </Box>
      </Container>
      <div
        className={"stageBarContainerBottomWrap"}
        style={{
          width: "100%",
          height: "150px",
          display: "flex",
          border: "none",
          justifyContent:
            stageBarWidth > stageBarContainerBottomWrapRef.current?.offsetWidth
              ? "left"
              : "center",
          padding: "0px",
          position: "relative",
        }}
        ref={stageBarContainerBottomWrapRef}
      >
        
        <StageBar
          preparation={preparation}
          btnObj={btnObj}
          setBtnObj={setBtnObj}
          isFold={isFold}
          setStageObj={setStageObj}
          setIsRefinement={setIsRefinement}
          isRefinement={isRefinement}
          attachmentObj={attachmentObj}
          setRefinementKey={setRefinementKey}
          refinementKey={refinementKey}
          setStagebarHeight={setStagebarHeight}
          setStageBarWidth={setStageBarWidth}
          maxRefine={maxRefine}
          setMaxRefine={setMaxRefine}
          attachStage={attachStage}
          stageBarWidth={stageBarWidth}
          getTxPlans={getTxPlans}
          uiShowType={uiShowType}
          stageMaxWidth={stageMaxWidth}
          currentPlan={currentPlan}
        />
      </div>

      <DialogBox open={deleteTxWarning>=0} title="Warning" content={<>{"Do you want to delete the plan " + (deleteTxWarning >= 0 ? (deleteTxWarning+1) : "") + "?"}</>} actions=
      {<>
          <UButton
            onClickHandler={ () => {
              console.info("=====delete tx: ", deleteTxWarning)
              if(deleteTxWarning === 0) {
                delTx(1)
              } else if(deleteTxWarning === 1) {
                delTx(2)
              }
              setDeleteTxWarning(-1)
            }}
            variant={"text"}
            btnText={("Yes")}
          />
          <UButton
            onClickHandler={ () => {
              setDeleteTxWarning(-1)
            }}
            variant={"text"}
            btnText={("No")}
          />
        </>
      }
      >
      </DialogBox>

      <DialogBox open={addEmptyTxWarning} title="Warning" content={<>{"Should not add two empty Tx at the same time."}</>} actions=
      {<>
          <UButton
            onClickHandler={ () => {
              setAddEmptyTxWarning(false)
            }}
            variant={"text"}
            btnText={("Ok")}
          />
        </>
      }
      >
      </DialogBox>

      <DialogBox open={noPermitGoPassiveAlignerWarning} title="Warning" content={<>{"The total number of current plan stages is 0."}</>} actions=
      {<>
          <UButton
            onClickHandler={ () => {
              setNoPermitGoPassiveAlignerWarning(false)
            }}
            variant={"text"}
            btnText={("Ok")}
          />
        </>
      }
      >
      </DialogBox>

      {/* <Popover
        id={popoverId}
        anchorEl={anchorEl}
        open={open}
        onClose={closeListItems}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "220px",
            marginLeft: "-65px",
            marginTop: "18px",
            background: "#263238",
          },
        }}
      >
        <div
          style={{
            background: "#263238",
            padding: "8px 0",
            borderRadius: "4px",
          }}
        >
          {menuList()}
        </div>
      </Popover>
      <Popover
        id={bitePopoverId}
        anchorEl={biteJumpAnchorEl}
        open={biteOpen}
        onClose={closeBiteListItems}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "138px",
            marginLeft: "-90px",
            marginTop: "4px",
            background: "#263238",
          },
        }}
      >
        <div
          style={{
            background: "#263238",
            padding: "8px 0",
            borderRadius: "4px",
          }}
        >
          {biteMenuList()}
        </div>
      </Popover> */}
      {/* <DialogBox
        open={deleteTxDialogShow}
        title={t("treat.deleteTxTitle")}
        content={
          <span style={{ fontSize: "14px", letterSpacing: "0.17px" }}>
            {t("treat.deleteTxText")}
          </span>
        }
        actions={
          <>
            <UButton
              onClickHandler={closeDeleteDialog}
              variant={"text"}
              btnText={t("treat.deleteTxCancel")}
            />
            <UButton
              onClickHandler={deleteTxClick}
              variant={"text"}
              btnText={t("treat.deleteTxDelete")}
            />
          </>
        }
      /> */}
      {/* <DialogBox
        open={renameTxDialogShow}
        title={t("treat.renameTxTitle")}
        content={
          <TextField
            label={""}
            fullWidth
            helperText={
              !isRenameError
                ? t("treat.renameTxHelperText")
                : t("treat.renameErrorText")
            }
            name={"planName"}
            type={"text"}
            onChange={(e) => {
              handleChangeTxName(e.target.value)
            }}
            value={reNameInputText}
            error={isRenameError}
          />
        }
        actions={
          <>
            <UButton
              onClickHandler={closeRenameDialog}
              variant={"text"}
              btnText={t("treat.deleteTxCancel")}
            />
            <UButton
              onClickHandler={renameTxClick}
              variant={"contained"}
              btnText={t("treat.renameTxConfirm")}
            />
          </>
        }
      /> */}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={helpOpen}
      >
        <Box className={"helpBox"}>
          <div className={"helpHead"}>
            <span>{t("treat.help.title")}</span>
            <div
              onClick={() => {
                setHelpOpen(false)
              }}
              className={"helpClose"}
            ></div>
          </div>
          <div className={"helpWrap"}>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>{t("treat.help.stageNumber")}</div>
              <div className={"rightBox"}>1</div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>
                {t("treat.help.courseRefinement")}
              </div>
              <div className={"rightBox"}>
                <div className={"refinement"}>C1</div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>{t("treat.help.currentStage")}</div>
              <div className={"rightBox"}>
                <div className={"current"}></div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>
                {t("treat.help.attachmentInstallation")}
              </div>
              <div className={"rightBox"}>
                <div className={"attachInstall"}></div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>
                {t("treat.help.attachmentRemoval")}
              </div>
              <div className={"rightBox"}>
                <div className={"attachRemove"}></div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>{t("treat.help.IPR")}</div>
              <div className={"rightBox"}>
                <div className={"ipr"}></div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>
                {t("treat.help.passiveAligner")}
              </div>
              <div className={"rightBox"}>
                <div className={"passive"}>
                  <div className={"passiveCir"}></div>
                </div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>
                {t("treat.help.completedStage")}
              </div>
              <div className={"rightBox"}>
                <div className={"completed"}>
                  <div className={"completedCir"}></div>
                </div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>{t("treat.help.keyFrame")}</div>
              <div className={"rightBox"}>
                <div className={"keyframe"} style={{ marginLeft: "4px" }}>
                  <div className={"keyframeCir"}></div>
                </div>
              </div>
            </div>
            <div className={"helpLine"}>
              <div className={"leftTitle"}>{t("treat.help.vccStages")}</div>
              <div className={"rightBox"}>
                <div className={"vccBox"}>
                  <div className={"keyframe"}>
                    <div className={"keyframeCir"}></div>
                  </div>
                  <div className={"keyframe"}>
                    <div className={"keyframeCir"}></div>
                  </div>
                  <div className={"keyframe"}>
                    <div className={"keyframeCir"}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Backdrop> */}
    </Box>
  )
}
export default Toggle
