import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import CreateCaseIcon from "@mui/icons-material/AddCircleOutline"
import BoltonIcon from "../assets/left/ruler.svg"
import RxIcon from "../assets/left/rx.svg"
import InsertPhoto from "@mui/icons-material/InsertPhoto"
import ChatIcon from "@mui/icons-material/Chat"
import PhotoIcon from "@mui/icons-material/PhotoOutlined"
import LRPhotosIcon from "@mui/icons-material/PhotoAlbum"
import LRStlIcon from "@mui/icons-material/AddOutlined"
import ReportIcon from "@mui/icons-material/PictureAsPdf"
import ArticleIcon from "@mui/icons-material/Article"
import AssignmentIcon from "@mui/icons-material/Assignment"
import TableChartIcon from "@mui/icons-material/TableChart"
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined"
import BuildIcon from "@mui/icons-material/Build"
import Correction1 from "../assets/left/correction/1.svg"
import Correction1_1 from "../assets/left/correction/1_1.svg"

import {
  Badge,
  Box,
  ToggleButton,
  Paper,
  Stack,
  ToggleButtonGroup,
  Tooltip,
  Button,
  SvgIcon,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

import Tooth from "../assets/left/tooth-outline.svg"
import { setCurLeftPage } from "../udTreatSlice"
import { useTheme } from "@mui/material/styles"
import { setSmartRxShow } from "../udTreatSlice"
export function LeftToolBar(props: any) {
  const theme = useTheme()
  const {
    isCasePreview,
    curCaseMode,
    curLeftPage,
    curRightPage,
    ucloud1_1,
    isNewCaseHasUpload,
    smartRxShow,
    openHistorySplitView,
    singleArchBool,
    isCreateNewRefinement,
    isDoingPresetup,
    uassistRxShow
  } = useAppSelector((state: RootState) => state.udTreatService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [toolName, SetToolName] = useState("")
  const dispatch = useAppDispatch()
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextTool: string,
  ) => {
    if (nextTool === "smartRx") {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
        dispatch(setCurLeftPage(""))
      } else {
        dispatch(setSmartRxShow(true))
        dispatch(setCurLeftPage("smartRx"))
      }
    } else {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
        dispatch(setCurLeftPage(""))
      }
    }
    SetToolName(nextTool)
    dispatch(setCurLeftPage(nextTool))
  }
  const [selPhoto, setSelPhoto] = useState(false)

  useEffect(() => {
    if (
      caseDetail.caseDisposition === "ATTENTION_NEEDED" ||
      caseDetail.caseDisposition === "REVIEW_PLAN" ||
      caseDetail.caseDisposition === "REVIEW_MODIFICATION"
    ) {
      handleChange(undefined, "uassist")
    }
  }, [caseDetail.caseDisposition])

  const splitViewBtns = [
    {
      title: "uAssist Messages",
      value: "uassist",
      icon: (
        <ChatIcon color={curLeftPage === "uassist" ? "primary" : "inherit"} />
      ),
      onClick: () => {
        handleChange(undefined, "uassist")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false,
    },
    {
      title: "Prescription",
      value: "smartRx",
      icon: (
        <ReactSVG
          src={RxIcon}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (curLeftPage === "smartRx") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
        ></ReactSVG>
      ),
      onClick: () => {
        handleChange(undefined, "smartRx")
      },
      isHide: false,
      isDisable: (caseDetail.uassist_round ?? 0) <= 0,
    },
    {
      title: "Photos and X-Rays",
      value: "records",
      icon: (
        <InsertPhoto
          color={curLeftPage === "records" ? "primary" : "inherit"}
        />
      ),
      onClick: () => {
        if (curLeftPage === "records") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "records")
      },
      isHide: false,
      isDisable: false,
    },
  ]

  const LeftBtnColumn = [
    {
      title: "uAssist Messages",
      value: "uassist",
      icon: (
        <ChatIcon color={curLeftPage === "uassist" ? "primary" : "inherit"} />
      ),
      onClick: () => {
        handleChange(undefined, "uassist")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false,
    },
    {
      title: "Upload Scan",
      value: "createcase",
      icon: (
        <CreateCaseIcon
          color={curLeftPage === "createcase" ? "primary" : "inherit"}
        />
      ),
      onClick: () => {
        if (curLeftPage === "createcase") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "createcase")
      },
      isHide:
        caseDetail.caseDisposition !== "SUBMITTED_TO_UASSIST" &&
        caseDetail.caseDisposition !== "SUBMIT_TO_UASSIST",
      isDisable: false,
    },
    {
      title: "Prescription",
      value: "smartRx",
      icon: (
        <ReactSVG
          src={RxIcon}
          style={{ width: "27px" }}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (smartRxShow) {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else if (
              caseDetail.uassist_progress === "NO_UASSIST" || isCreateNewRefinement
            ) {
              path?.setAttribute("fill", "#00000061")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
        ></ReactSVG>
      ),
      onClick: () => {
        handleChange(undefined, "smartRx")
      },
      isHide: false,
      isDisable: isCreateNewRefinement || uassistRxShow,
    },
    {
      title: "Photos and X-Rays",
      value: "records",
      icon: (
        <InsertPhoto
          color={curLeftPage === "records" ? "primary" : "inherit"}
        />
      ),
      onClick: () => {
        if (curLeftPage === "records") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "records")
      },
      isHide: false,
      isDisable: false,
    },
    {
      title: "IPR/Aux Report",
      value: "report",
      icon: (
        <AssignmentIcon
          color={curLeftPage === "report" ? "primary" : "inherit"}
        />
      ),
      onClick: () => {
        if (curLeftPage === "report") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "report")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false,
    },
    {
      title: "Tooth Movement Table",
      value: "tmtable",
      icon: (
        <TableChartIcon
          color={curLeftPage === "tmtable" ? "primary" : "inherit"}
        />
      ),
      onClick: () => {
        if (curLeftPage === "tmtable") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "tmtable")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false,
    },
    {
      title: "Bolton Analysis",
      value: "bolton",
      icon: (
        <ReactSVG
          src={BoltonIcon}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (curLeftPage === "bolton") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else if (singleArchBool) {
              path?.setAttribute("fill", "#00000061")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
        ></ReactSVG>
      ),
      onClick: () => {
        if (curLeftPage === "bolton") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "bolton")
      },
      isHide:
        caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
        caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
        caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: singleArchBool,
    },
  ]
  const UcloudButtons = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={curLeftPage}
        exclusive
        onChange={handleChange}
      >
        {openHistorySplitView
          ? splitViewBtns.map(
              ({ title, icon, onClick, isHide, isDisable, value }, index) => {
                if (isHide) return
                return (
                  <Tooltip title={title} placement="right" key={index}>
                    <span>
                      <ToggleButton
                        sx={{
                          border: "none",
                          borderRadius: "8px",
                          "&:hover": {
                            borderRadius: "8px",
                          },
                          "&.Mui-selected": {
                            borderRadius: "8px",
                          },

                          "&.Mui-disabled": {
                            border: "none",
                            boxShadow: "none",
                          },
                        }}
                        value={value}
                        onClick={onClick}
                        disabled={isDisable}
                      >
                        {icon}
                      </ToggleButton>
                    </span>
                  </Tooltip>
                )
              },
            )
          : LeftBtnColumn.map(
              ({ title, icon, onClick, isHide, isDisable, value }, index) => {
                if (isHide) return

                if (title === "Upload Scan" && !isCasePreview) return
                return (
                  <Tooltip title={title} placement="right" key={index}>
                    <span>
                      <ToggleButton
                        sx={{
                          border: "none",
                          borderRadius: "8px",
                          "&:hover": {
                            borderRadius: "8px",
                          },
                          "&.Mui-selected": {
                            borderRadius: "8px",
                          },

                          "&.Mui-disabled": {
                            border: "none",
                            boxShadow: "none",
                          },
                        }}
                        value={value}
                        size="medium"
                        onClick={onClick}
                        disabled={isDisable}
                      >
                        {icon}
                      </ToggleButton>
                    </span>
                  </Tooltip>
                )
              },
            )}
      </ToggleButtonGroup>
    )
  }

  const PatientInfoBtnGroup = () => {
    return (
      <>
        <Tooltip title="Photos and X-Rays" placement="right">
          <ToggleButton
            sx={{ border: "none" }}
            value={"records"}
            size="medium"
            onClick={() => {
              if (curLeftPage === "records") {
                handleChange(undefined, "")
                return
              }
              handleChange(undefined, "records")
            }}
          >
            {/* <Badge
              sx={{
                width: 8,
                height: 8,
                position: "absolute",
                right: 4,
                top: 4,
                borderRadius: "100px",
                background: "#C62828 ",
              }}
            /> */}
            <InsertPhoto
              color={curLeftPage === "records" ? "primary" : "inherit"}
            />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Dental Chart" placement="right">
          <ToggleButton
            sx={{ border: "none" }}
            value={"dentalchart"}
            size="medium"
            // sx={{ marginTop: 1 }}
            onClick={() => {
              handleChange(undefined, "dentalchart")
            }}
          >
            {/* <Badge
              sx={{
                width: 8,
                height: 8,
                position: "absolute",
                right: 4,
                top: 4,
                borderRadius: "100px",
                background: "#C62828 ",
              }}
            /> */}
            <ReactSVG
              src={Tooth}
              afterInjection={(svg) => {
                const path = svg.getElementsByTagName("path")[0]
                if (curLeftPage === "dentalchart") {
                  path?.setAttribute("fill", "#215ECD")
                  path?.setAttribute("fill-opacity", "1")
                } else {
                  path?.setAttribute("fill", "black")
                  path?.setAttribute("fill-opacity", "0.56")
                }
              }}
            ></ReactSVG>
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Smart Rx" placement="right">
          <ToggleButton
            sx={{ border: "none" }}
            onClick={() => {
              handleChange(undefined, "smartRx")
            }}
            value="smartRx"
          >
            <EditCalendarOutlinedIcon />
          </ToggleButton>
        </Tooltip>
      </>
    )
  }
  return (
    <Box
      sx={{
        width: "48px",
        left: 28,
        top: 98,
        maxHeight: `calc(100% - 114px)`,
        overflow: "auto",
        "::-webkit-scrollbar": {
          width: "1px",
        },
        position: "absolute",
      }}
    >
      {isDoingPresetup ? (
        <Box
          sx={{
            backgroundColor: theme.transBkColor.light,
            borderRadius: "8px",
            // flexDirection: "column",
          }}
        >
          <Tooltip title="Photos and X-Rays " placement="right">
            <ToggleButton
              sx={{ border: "none" }}
              value={"records"}
              onClick={() => {
                if (curLeftPage === "records") {
                  handleChange(undefined, "")
                  return
                }
                handleChange(undefined, "records")
              }}
            >
              <InsertPhoto
                color={curLeftPage === "records" ? "primary" : "inherit"}
              />
            </ToggleButton>
          </Tooltip>
        </Box>
      ) : (
        <>
          {caseDetail.caseDisposition !== "SUBMITTED_TO_UASSIST" && (
            <Box
              sx={{
                backgroundColor: theme.transBkColor.light,
                borderRadius: "8px",
                // flexDirection: "column",
              }}
            >
              <Tooltip title="CorrectionTool " placement="right">
                <ToggleButton
                  sx={{ border: "none" }}
                  value={"correctionTool"}
                  onClick={() => {
                    if (curLeftPage === "correctionTool") {
                      handleChange(undefined, "")
                      return
                    }
                    handleChange(undefined, "correctionTool")
                  }}
                >
                  <ReactSVG
                    src={
                      curLeftPage === "correctionTool"
                        ? Correction1_1
                        : Correction1
                    }
                  />
                </ToggleButton>
              </Tooltip>
            </Box>
          )}
          {curLeftPage != "correctionTool" && (
            <>
              <Stack
                direction="column"
                sx={{
                  backgroundColor: theme.transBkColor.light,
                  borderRadius: "8px",
                  marginTop: 1,
                }}
              >
                <UcloudButtons />
              </Stack>
              {!openHistorySplitView &&
                (caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
                  caseDetail.caseDisposition === "PLANNING_IN_PROGRESS" ||
                  caseDetail.caseDisposition === "ATTENTION_NEEDED") && (
                  <Stack
                    direction="column"
                    sx={{
                      backgroundColor: theme.transBkColor.light,
                      marginTop: 1,
                      borderRadius: "8px",
                    }}
                  >
                    <Tooltip
                      title="Dental Chart"
                      placement="right"
                      sx={{ marginTop: 1 }}
                    >
                      <ToggleButton
                        sx={{ border: "none" }}
                        value={"dentalchart"}
                        size="medium"
                        // sx={{ marginTop: 1 }}
                        onClick={() => {
                          if (curLeftPage === "dentalchart") {
                            handleChange(undefined, "")
                            return
                          }
                          handleChange(undefined, "dentalchart")
                        }}
                      >
                        <ReactSVG
                          src={Tooth}
                          afterInjection={(svg) => {
                            const path = svg.getElementsByTagName("path")[0]
                            if (curLeftPage === "dentalchart") {
                              path?.setAttribute("fill", "#215ECD")
                              path?.setAttribute("fill-opacity", "1")
                            } else {
                              path?.setAttribute("fill", "black")
                              path?.setAttribute("fill-opacity", "0.56")
                            }
                          }}
                        ></ReactSVG>
                      </ToggleButton>
                    </Tooltip>
                  </Stack>
                )}
              {caseDetail.caseDisposition !== "SUBMITTED_TO_UASSIST" && (
                <Stack
                  direction="column"
                  sx={{
                    backgroundColor: theme.transBkColor.light,
                    marginTop: 1,
                    borderRadius: "8px",
                  }}
                >
                  <Tooltip title="Notes" placement="right">
                    <ToggleButton
                      sx={{ border: "none" }}
                      value={"notes"}
                      onClick={() => {
                        if (curLeftPage === "notes") {
                          handleChange(undefined, "")
                          return
                        }
                        handleChange(undefined, "notes")
                      }}
                    >
                      <ArticleIcon
                        color={curLeftPage === "notes" ? "primary" : "inherit"}
                      />
                    </ToggleButton>
                  </Tooltip>
                </Stack>
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}
