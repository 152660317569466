import { useEffect, useLayoutEffect, useState } from "react"
import { useRef } from "react"
import { useParams } from "react-router-dom"
import { Box, Stack, ThemeProvider } from "@mui/material"
import { isEmpty } from "lodash"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import {
  getCaseById,
  getCaseByPatient,
  updateCaseById,
} from "@/core/app/slices/case/caseThunkApi"
import {
  forcelockCaseApi,
  lockCaseApi,
  unlockCaseApi,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { RootState } from "@/core/app/store"
import {
  caseManagement,
  viewEdit,
  wasmModule,
  treatView,
  stagingManager,
} from "@/gluelayer"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import CaseLockPopup from "@/modules/Clinical/components/CaseLockPopup/caseLockPopup"
import MessagePopup from "@/modules/Clinical/components/CloseCase/messagePopup"
import OrderPopup from "@/modules/Clinical/components/CloseCase/orderPopup"
import { ScreeshotReport } from "@/modules/Clinical/components/ReportPDF/screenshotReport"
import ToothHover from "@/modules/Clinical/components/ToothHover/toothhover"
import WasmCanvas from "@/wasm3d/WasmCanvas"

import { UDBanner } from "./Banner/UDBanner"
import { BottomStatusBar } from "./bottom/BottomStatusBar"
import { BottomToolBar } from "./bottom/BottomToolBar"
import {
  caseLockByCRB,
  caseLockByOtherUser,
  caseLockByShipped,
  caseLockByUassist,
} from "./bottom/Toggle/column"
import { ReopenCaseDlg } from "./components/ReopenCaseDlg"
import SmartRx from "./components/SmartRx"
import { UploadSTL } from "./components/UploadSTL"
import { LeftPages } from "./left/LeftPages"
import { LeftToolBar } from "./left/LeftToolBar"
import { RightPages } from "./right/RightPages"
import { RightToolBar } from "./right/RightToolBar"
import { darkTheme, lightTheme } from "./theme/UDThemes"
import { ThemeSwitch } from "./top/ThemeSwitch"
import { TopToolBar } from "./top/TopToolBar"
import BiteRampButtons from "./UDesign/Attachments/BiteRampButtons"
import ExtractedToothList from "./UDToothInfo/ExtractedToothList"
import UDToothInfo from "./UDToothInfo/UDToothInfo"
import { KFEditor } from "./WeDesign/KFEditor"
import { WeStageBar } from "./WeDesign/WeStageBar"
import { UDChairsideWorkflow } from "./workflow/chairside/UDChairsideWorkflow"
import { UDRetainerRxWorkflow } from "./workflow/retainer/UDRetainerWorkflow"
import { UDSmartRxWorkflow } from "./workflow/smartrx/UDSmartRxWorkflow"
import { UDLockCaseForEdit } from "./UDLockCaseForEdit"
import { UDOpenCase } from "./UDOpenCasev2"
import NoPopup from "@/modules/Clinical/components/CloseCase/noPopup"
import AssignPopup from "@/modules/Clinical/components/CloseCase/assignPopup"
import { CreateRefinement } from "@/modules/Records/CreateRefinement"
import {
  setPlanList,
  setSwitchViewEdit,
  setSmartRxShow,
  setUassistRxShow,
  setStageShowType,
  setIsCreateNewRefinement,
  setWidthAll,
  clearTreatStates,
  setIsDoingPresetup,
  setCurLeftPage,
} from "./udTreatSlice"

import {
  fetchZipsAndLoadCase
} from "@/core/app/slices/clinical/opencaseflow"

import {
  resetPrescriptionSchema,
  resetRxRefinementFormAdd,
  resetRxRefinementFormEdit,
  resetSchema,
} from "@/core/app/slices/uassist/formSlice"
import { refinementModule } from "@/gluelayer/core/modules/refinement"
import { setPhotoTypeJson } from "@/core/app/slices/records/photograph/photographSlice"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import AttachmentWarning from "./UDesign/Attachments/AttachmentWarning"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { requestCloudPresetup } from "@/core/app/slices/v2/v2apis"
import { setOpenReopenCaseDlg } from "@/UDTreat/udTreatSlice"
import { SketchPicker } from "react-color"
import ColorPicker from "./bottom/ColorPicker"
import { ErrorDialogBox } from "./bottom/DialogBox/errorDialogBox"
import AutoStep from "./top/AutoStep/AutoStep"
import AutoBottom from "./bottom/AutoBottom/AutoBottom"

export const UDTreat = () => {
  const dispatch = useAppDispatch()
  const {
    curCaseMode,
    workflowMode,
    isCasePreview,
    showBottomStatusBar,
    planList,
    reportData,
    switchViewEdit,
    refinementNum,
    isCreateNewRefinement,
    isDoingPresetup,
    goAutoProcesses,
  } = useAppSelector((state: RootState) => state.udTreatService)
  const navigate = useMyNavigation()
  const [isSubmitModification, setisSubmitModification] = useState(true)
  const [isSubmitReply, setisSubmitReply] = useState(true)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { isCaseFilesUpdated } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const { txplanSubname, txplanName } = caseDetail
  const { caseId, patientId } = useParams()
  const [openCase, setOpenCase] = useState(true)
  const [darkMode, setDarkMode] = useState(false)
  const [openWarning, setOpenWarning] = useState(true)
  const { current_orgId } = useAppSelector(
    (state: RootState) => state.userService.user,
  )

  const [isShowPresetupError, setIsShowPresetupError] = useState(false)
  const [presetupErrorType, setPresetupErrorType] = useState("")

  const pageRef = useRef(null)
  useEffect(() => {
    // don't need to dispatch getCaseById here, because it's already dispatched in the parent component
    dispatch(getCaseByPatient({ patientId }))
    // dispatch(getCaseById({ patientId, caseId }))
    // clear rx data
    dispatch(resetSchema())
    dispatch(resetPrescriptionSchema())
    dispatch(resetRxRefinementFormEdit())
    dispatch(resetRxRefinementFormAdd())

    // clear photos json
    dispatch(setPhotoTypeJson(null))
    // open case
    dispatch(fetchZipsAndLoadCase({ caseId, patientId }))

    return () => {
      dispatch(clearTreatStates())
    }
  }, [])

  const requestPresetupAlready = useRef<boolean>(false)

  const requestAiPresetup = async (
    hasUpper,
    hasLower,
    udesign_json,
    force = false,
  ) => {
    await dispatch(
      requestCloudPresetup({
        orgId: current_orgId,
        upper: hasUpper,
        lower: hasLower,
        patientId,
        caseId,
        force,
      }),
    ).then((res) => {
      dispatch(setCurLeftPage(""))
      switch (res.payload) {
        case true:
          dispatch(setOpenReopenCaseDlg(true))
          dispatch(setIsDoingPresetup(false))
          const newUdesignJsonObj = {
            ...udesign_json,
            needPresetup: false,
          }
          dispatch(
            updateCaseById({
              patientId,
              caseId,
              payload: {
                case_extra_attrs: JSON.stringify(newUdesignJsonObj),
              },
            }),
          )
          break
        case "failed":
          setPresetupErrorType("failed")
          setIsShowPresetupError(true)
          break
        case "retry":
          setPresetupErrorType("retry")
          setIsShowPresetupError(true)
          break
        case "network error":
          setPresetupErrorType("network error")
          setIsShowPresetupError(true)
          break
      }
    })
  }
  useEffect(() => {
    const requestPresetup = async (udesign_json) => {
      let hasUpper = true,
        hasLower = true
      hasUpper = udesign_json.upperUploaded
      hasLower = udesign_json.lowerUploaded
      requestAiPresetup(hasUpper, hasLower, udesign_json)
    }
    if (caseDetail && caseDetail.udesign_json) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      console.log("udesign_json before presetup:::::::::::::::::", udesign_json)
      if (udesign_json.needPresetup && !requestPresetupAlready.current) {
        requestPresetupAlready.current = true
        requestPresetup(udesign_json)
        dispatch(setIsDoingPresetup(true))
        dispatch(setCurLeftPage("records"))
        console.log("setIsDoingPresetup")
      }
    }
  }, [caseDetail])

  // 添加新的 useEffect 用于设置键盘事件监听器
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        console.log("handleKeyDown")
        const inRefinement = wasmModule.statusController.IsInRefinementModule()
        if (inRefinement) return
        switch (event.key.toLowerCase()) {
          case "z":
          case "y":
            event.preventDefault()
            console.log("undo/redo key pressed")
            if (!wasmModule.isInit) return
            const module = wasmModule.ulabwinIns.getModuleManager()
            const undoRedoModule = module.GetUndoOrRedoModule()
            if (undoRedoModule) {
              if (event.key.toLowerCase() === "z") {
                console.log("Executing undo")
                undoRedoModule.GetLastState()
              } else {
                console.log("Executing redo")
                undoRedoModule.GetNextState()
              }
            }
            break
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    // 清理函数
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, []) // 空依赖数组意味着这个效果只在组件挂载和卸载时运行

  // Assembly plan data
  const assemblyPlanData = (planData: string[]) => {
    const txArr = []
    planData.map((i, j) => {
      txArr.push({
        txName: i.trim(),
        index: j + 1,
      })
    })
    return txArr
  }
  // function to deletePlan
  const deletePlanFunction = (plan = currentPlan.index) => {
    const isDel = caseManagement.delTx(plan)
    const txList = caseManagement.getTreatmentPlanList()
    const txArr = assemblyPlanData(txList)
    setCurrentPlan(txArr[0])
    setPlanList(txArr)
    return isDel
  }
  // order states
  const [isClickOrder, setisClickOrder] = useState(false)
  // case lock popup
  const [openLockPopup, setOpenLockPopup] = useState<boolean>(false)
  // case lock type
  //-1--- CRB  ,0---lock by uassist,   1---order been place,  2----edit by other user  ,
  const [lockType, setLockType] = useState<number>(2)
  const [allowUnlock, setAllowUnlock] = useState(true)
  const [ispopupAfterOrder, setispopupAfterOrder] = useState(false)
  // lock by username and machine
  const [lockInfo, setLockInfo] = useState<{
    username: string
    device_info: string
    locked_date: string
  }>({ username: "", device_info: "", locked_date: "" })
  // click lock control or override button
  const clickControlOverride = () => {
    dispatch(forcelockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        dispatch(setSwitchViewEdit(true))
        setOpenLockPopup(false)
      }
    })
  }
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    switchViewEdit || isClickOrder || !isSubmitReply,
  )
  // current tx
  const [currentPlan, setCurrentPlan] = useState<{
    txName: string
    index: 2 | 1
  }>({})

  //max refine
  const [maxRefine, setMaxRefine] = useState<number>(0)
  // pdf shoot
  const reportPdfRef = useRef(null)
  const openOrUploadReportPdf = (status: "open" | "upload") => {
    reportPdfRef?.current.uploadReportPDF(status)
  }
  // lock case function
  // unlock request
  const unlockRequest = () => {
    dispatch(unlockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        dispatch(setSwitchViewEdit(false))
      } else {
        const { errors } = payload
        const { error_message } = errors[0]
        alert(error_message)
      }
    })
  }
  // unlock post request
  const unlockFunc = () => {
    uploadAllData({ callback: unlockRequest })
  }
  // lock post request
  const lockFunc = (callBack?: () => void, allowControl = true) => {
    dispatch(lockCaseApi({ patientId, caseId })).then(({ payload }) => {
      const { status } = payload
      if (status === "Success") {
        callBack && callBack()
      } else {
        const { errors, username, device_info, locked_date } = payload.data
        const { error_message } = errors[0]
        const info = error_message.split("::")[0]
        if (caseLockByUassist.includes(info)) {
          setLockType(0)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        } else if (caseLockByShipped.includes(info)) {
          setLockType(1)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        } else if (caseLockByOtherUser.includes(info)) {
          setLockInfo({ username, device_info, locked_date })
          setLockType(switchViewEdit ? 3 : 2)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        } else if (caseLockByCRB.includes(info)) {
          setLockType(-1)
          setAllowUnlock(allowControl)
          setOpenLockPopup(true)
        }
      }
    })
  }
  // switch edit or view function
  const handleSwitchEdit = () => {
    if (!switchViewEdit) {
      // lock case
      lockFunc(() => {
        dispatch(setSwitchViewEdit(true))
      })
    } else {
      // unlock case
      unlockFunc()
    }
  }

  const handerOrderClick = (callBack) => {
    lockFunc(() => {
      callBack()
    }, false)
  }
  /**
   * update the case detail by the case id
   * @param txname:the active case
   * @param other: the other payload for the case detail api
   */
  const updateCaseFun = ({ txname = planList[0].txName, ...other }) => {
    let extralInfo = {}
    // extralInfo.UpperRetainer = 1
    // extralInfo.LowerRetainer = 1
    let newUdesignJsonObj = {}
    let isHasAttachmentData
    let isHasIPRData
    try {
      extralInfo = caseManagement.getCaseExtraInfomation()
      isHasAttachmentData =
        caseManagement.getAttachmentIPRData()?.isHasAttachmentData
      isHasIPRData = caseManagement.getIsHasIPRNode()
    } catch {
      return
    }
    const isStartFromOne = refinementModule.getIsStartFromOne()
    dispatch(getCaseById({ patientId, caseId })).then((res) => {
      if (res?.payload?.udesign_json) {
        const udesign_json = JSON.parse(res.payload.udesign_json)
        //Merge with existing JSON.
        newUdesignJsonObj = {
          ...udesign_json,
          ...extralInfo,
        }
      } else {
        newUdesignJsonObj = { ...extralInfo }
      }
      const txsubname = maxRefine
        ? `Course Refinement ${maxRefine}`
        : planList[0].txName !== txname
        ? planList[0].txName
        : planList[1]?.txName || ""
      dispatch(
        updateCaseById({
          patientId,
          caseId,
          payload: {
            case_extra_attrs: JSON.stringify(newUdesignJsonObj),
            txplan_name: txname,
            txplan_subname: txsubname,
            course_check_number: isStartFromOne ? refinementNum - 1 : 0,
            complexity: isHasAttachmentData || isHasIPRData ? 2 : 1,
            planned_upper:
              Number(
                stagingManager.wasmStageData.jointUpKeypoints[
                  stagingManager.wasmStageData.jointUpKeypoints.length - 1
                ]?.name,
              ) || 0,
            planned_lower:
              Number(
                stagingManager.wasmStageData.jointLowerKeypoints[
                  stagingManager.wasmStageData.jointLowerKeypoints.length - 1
                ]?.name,
              ) || 0,
            ...other,
          },
        }),
      )
    })
  }
  /**
   * upload all of the datas when close the case
   * @param txname:active plan in the case(modifi plan or assign plan or order plan)
   * @param txsubname:inactive plan in the case
   * @param callback:the function after upload zips
   * @param other: the payload for upload case detail
   */
  const uploadAllData = ({
    txname = txplanName,
    txsubname = txplanSubname,
    callback = () => {
      return
    },
    ...other
  }: {
    txname?: string
    txsubname?: string
    callback: () => void
    other
  }) => {
    if (!txname) {
      // if there is no txname in the DB
      txname = planList[0]?.txName
    }
    if (!txsubname && planList[1]) {
      // if there is no txsubname in the DB
      txsubname = planList[1]?.txName
    }
    // if there is no plan
    if (!txname) {
      callback && callback()
      return
    }
    lockFunc(() => {
      updateCaseFun({ txname, txsubname, ...other })
      // update pdf
      openOrUploadReportPdf("upload")

      let uploadFolders: any = ["bulk2"]
      const jsonStr = wasmModule.statusController.GetDoneActionType()
      console.log("GetDoneActionType: ", jsonStr)
      if (jsonStr !== "") {
        const jsonObject = JSON.parse(jsonStr)
        for (let key in jsonObject) {
          if (!uploadFolders.includes("bulk1")) {
            if (
              key === "Resegment" ||
              key === "Relabel" ||
              key === "Space Correction"
            ) {
              uploadFolders.push("bulk1")
            }
          }
          if (!uploadFolders.includes("bulk10")) {
            if (key === "Resegment") {
              uploadFolders.push("bulk10")
            }
          }
        }
      }

      // update zips
      caseManagement.getCaseFiles(uploadFolders).then((r) => {
        const zips = []
        if (r) {
          for (const k in r) {
            zips.push({
              fileName: k,
              file: r[k],
            })
          }

          dispatch(
            uploadCaseZips({
              zips,
              patientId,
              caseId,
            }),
          ).then(({ payload }) => {
            if (zips.length === payload.length) {
              for (let i = 0; i < payload.length; i++) {
                if (payload[i].status !== 200) {
                  return
                }
              }
              // caseManagement.closeCase()
              callback && callback()
            }
            wasmModule.statusController.SetDoneActionType("None")
          })
        }
      })
    })
  }
  // 传递给3d view or edit
  useEffect(() => {
    if (!wasmModule.isInit) return
    viewEdit.switchViewToEdit(switchViewEdit)
  }, [switchViewEdit])

  useLayoutEffect(() => {
    return () => {
      console.log("clear wasm memory")
      caseManagement.closeCase()
    }
  }, [])

  useEffect(() => {
    console.info("treat currentPlan: ", currentPlan.txName, currentPlan.index)
  }, [currentPlan])

  useEffect(() => {
    console.info("treat planList: ", planList)
  }, [planList])

  // left smartRx
  const { smartRxShow, uassistRxShow } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box
        id={"TreatmentRoot"}
        ref={pageRef}
        sx={{
          display: "flex",
          top: 60,
          left: 0,
          bottom: 0,
          width: "100%",
          flexDirection: "column",
          border: "none",
        }}
      >
        <ColorPicker />
        <Box sx={{ flexGrow: 1, overflow: "hidden" }} position="relative">
          {isDoingPresetup && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                position: "absolute",
                bottom: 60,
                margin: "auto",
                width: "100%",
                textAlign: "left",
                backgroundColor: "transparent",
                color: "white",
                padding: "10px 0",
                zIndex: 1000,
                fontSize: "1.3rem",
              }}
            >
              AI System is working to prepare the case for self-planning (will
              take up to 5 mins).
              <br />
              <br />
              You can wait for case to reload automatically when it's ready. Or
              you can close the case <br /> now and re-open it later from
              patient list.
            </Box>
          )}
          <WasmCanvas />

          <TopToolBar
            setLockType={setLockType}
            setOpenLockPopup={setOpenLockPopup}
            setisClickOrder={setisClickOrder}
            setispopupAfterOrder={setispopupAfterOrder}
            switchEditCallBack={() => {
              handleSwitchEdit()
            }}
            isEdit={switchViewEdit}
            ispopup={isClickOrder || !isSubmitReply}
            orderClick={handerOrderClick}
          />
          {goAutoProcesses && <AutoBottom />}
          <BottomToolBar
            parentRef={pageRef}
            setTreatCurrentPlan={setCurrentPlan}
          />
          <ExtractedToothList />
          <ThemeSwitch
            sx={{
              position: "absolute",
              right: "180px",
              top: "-45px",
            }}
            value={darkMode}
            onChange={(e) => {
              setDarkMode(e.target.checked)
            }}
          />
          {workflowMode === "aidesign" && !goAutoProcesses && <LeftToolBar />}
          <LeftPages
            isEdit={switchViewEdit}
            setisSubmitModification={setisSubmitModification}
            setisSubmitReply={setisSubmitReply}
            isSubmitReply={isSubmitReply}
            isSubmitModification={isSubmitModification}
            showPrompt={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            updateCaseFun={updateCaseFun}
          />
          <UDToothInfo isOpenCase={openCase} isEdit={switchViewEdit} />
          {openCase && <ToothHover />}

          <RightToolBar />
          <RightPages />
          <BiteRampButtons />
          <SmartRx
            needToSubmit={false}
            smartRxShow={smartRxShow}
            setSmartRxShow={setSmartRxShow}
            styleObj={{ left: 84 }}
          />
          <SmartRx
            unlockFunc={unlockFunc}
            needToSubmit={true}
            smartRxShow={uassistRxShow}
            setSmartRxShow={setUassistRxShow}
            styleObj={{ right: 84 }}
          />
          <UDOpenCase
            open={openCase}
            onClose={() => {
              setOpenCase(false)
            }}
            switchEditCallBack={() => {
              handleSwitchEdit()
            }}
          />
          <UDLockCaseForEdit />
          <UDBanner
            open={openWarning}
            onOK={() => {
              setOpenWarning(false)
            }}
          />
          {workflowMode === "smartrx" && <UDSmartRxWorkflow />}
          {workflowMode === "retainerrx" && <UDRetainerRxWorkflow />}
          {/* {showBottomStatusBar && <BottomStatusBar />} */}
          {/* <BottomStatusBar /> */}
          {/* curCaseMode === "WeDesign" && <WeStageBar /> */}
          {curCaseMode === "WeDesign" && <KFEditor />}
          <ReopenCaseDlg />
          {isCreateNewRefinement && (
            <CreateRefinement
              onClose={() => {
                // setOpenRefDialog(false)
                treatView.Open(false)
                dispatch(setStageShowType("normal"))
                dispatch(setIsCreateNewRefinement(false))
              }}
            ></CreateRefinement>
          )}
        </Box>
        <AttachmentWarning />
        {/* {!isCasePreview && <BottomToolBar />} */}
        {/* close no popup */}
        <NoPopup
          showPrompt={showPrompt && isSubmitModification && isSubmitReply}
          confirmNavigation={confirmNavigation}
          isActive={(isClickOrder && !ispopupAfterOrder) || !isClickOrder}
          uploadAllData={uploadAllData}
          isClickOrder={isClickOrder}
        />
        {/* case lock popup */}
        <CaseLockPopup
          lockType={lockType}
          openLockPopup={openLockPopup}
          setOpenLockPopup={setOpenLockPopup}
          lockInfo={lockInfo}
          clickControlOverride={clickControlOverride}
          allowControl={allowUnlock}
          uploadAllData={uploadAllData}
          cancelNavigation={cancelNavigation}
        />
        {/* close case popup */}
        {/* <AssignPopup
          currentPlan={currentPlan}
          planList={planList}
          showPrompt={showPrompt && isSubmitModification}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
          isActive={!isClickOrder && caseDetail.caseDisposition === "REVIEW_PLAN"}
          uploadReportPdf={() => openOrUploadReportPdf("upload")}
          uploadAllData={uploadAllData}
          maxRefine={maxRefine}
        /> */}
        {/* if 2 plan popup*/}
        <OrderPopup
          currentPlan={currentPlan}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
          isClickOrder={
            isClickOrder && ispopupAfterOrder
            //&& isSubmitModification
          }
          setisClickOrder={setisClickOrder}
          setispopupAfterOrder={setispopupAfterOrder}
          planList={planList}
          uploadReportPdf={() => openOrUploadReportPdf("upload")}
          deletePlanFunction={deletePlanFunction}
          lockFunc={lockFunc}
          uploadAllData={uploadAllData}
        ></OrderPopup>
        {/** Fill in the full report pdf */}
        <ScreeshotReport
          // isReady={preparation}
          isReady={true}
          attachment={reportData.attachment}
          toothlist={reportData.toothlist}
          iprData={reportData.iprData}
          ref={reportPdfRef}
        />
        <MessagePopup
          cancelNavigation={cancelNavigation}
          showPrompt={showPrompt && (!isSubmitModification || !isSubmitReply)}
          setisSubmitModification={setisSubmitModification}
          setisSubmitReply={setisSubmitReply}
          isSubmitModification={isSubmitModification}
          uploadAllData={uploadAllData}
          confirmNavigation={confirmNavigation}
          isEdit={switchViewEdit}
        ></MessagePopup>
        <ErrorDialogBox
          isShow={isShowPresetupError}
          setIsShowPresetupError={setIsShowPresetupError}
          errorType={presetupErrorType}
          requestAiPresetup={() => {
            const udesign_json = JSON.parse(caseDetail.udesign_json)
            let hasUpper = true,
              hasLower = true
            hasUpper = udesign_json.upperUploaded
            hasLower = udesign_json.lowerUploaded
            requestAiPresetup(hasUpper, hasLower, udesign_json, true)
          }}
        ></ErrorDialogBox>
      </Box>
    </ThemeProvider>
  )
}
