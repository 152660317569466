import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  Step,
  Stepper,
  StepLabel,
  Button,
} from "../../../components/mui.components"
import { Warning, Info } from "@mui/icons-material"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

const AutoStep: FC = () => {
  const dispatch = useAppDispatch()
  const { autoProActiveStep } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const stepTmp = [
    {
      id: "check-segmentation",
      label: "Check Segmentation",
      icon: <Info />,
      activeIcon: <Info color={"primary"} />,
      index: 0,
    },
    {
      id: "check-label",
      label: "Check Label",
      icon: <Warning />,
      activeIcon: <Warning color={"primary"} />,
      index: 1,
    },
    {
      id: "check-bitereg",
      label: "Check Bite Reg",
      icon: <Info />,
      activeIcon: <Info color={"primary"} />,
      index: 2,
    },
    {
      id: "check-space",
      label: "Check Space",
      icon: <Warning />,
      activeIcon: <Warning color={"primary"} />,
      index: 3,
    },
    {
      id: "adjust-orientation",
      label: "Adjust Orientation",
      icon: <Info />,
      activeIcon: <Info color={"primary"} />,
      index: 4,
    },
  ]
  const { t } = useTranslation("common")

  return (
    <Box>
      <Stepper autoProActiveStep={autoProActiveStep} orientation="horizontal">
        {stepTmp.map((i) => {
          return (
            <Step key={i.id}>
              <StepLabel
                icon={i.index === autoProActiveStep ? i.activeIcon : i.icon}
              >
                {i.index === autoProActiveStep ? i.label : ""}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Box>
  )
}
export default AutoStep
